import React from "react";

const ReferenceNumber = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="referenceNumber">Kundennummer</label>
      <input
        type="text"
        id="referenceNumber"
        className="form-control"
        value={props.referenceNumber}
        onChange={props.handleChange}
        required={true}
      />
    </div>
  );
};

export default ReferenceNumber;
