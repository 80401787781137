import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import FetchError from "../../shared/FetchError";
import { baseUrl } from "../../../utils/baseUrl";
import { states } from "../../../utils/states";
import { dateToString } from "../../../utils/dates";

const DashboardModalTable = ({ modalRideState }) => {
  const [isError, setError] = useState(false);
  const [rides, setRides] = useState();

  useEffect(() => {
    fetchRides();
  }, []);

  const fetchRides = async () => {
    const today = new Date();

    const fromUrl = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()}`;
    const toUrl = `${today.getUTCFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()}`;

    let query =
      modalRideState === 1
        ? `from=${fromUrl}&to=${toUrl}&state=${modalRideState}`
        : `to=${toUrl}&state=${modalRideState}`;

    try {
      const response = await fetch(`${baseUrl}/rides/all?${query}`, {
        credentials: "include",
      });
      const result = await response.json();

      setRides(result.rides);
    } catch {
      setError(true);
    }
  };

  const renderTableBody = () => {
    if (!rides) {
      return;
    }

    const rows = rides.map((x, i) => (
      <tr key={i} id={i}>
        <td>
          {x.consumer &&
          x.consumer.company != null &&
          x.consumer &&
          x.consumer.company.length > 0
            ? x.consumer.company
            : x.consumer.name}
        </td>
        <td>
          {x.consumer.adress.zipcode} {x.consumer.adress.city}
        </td>
        <td>{x.consumer.adress.street}</td>
        <td>{x.car.license}</td>
        <td>{x.quantity}</td>
        <td>{x.consumer.interval}</td>
        <td>{dateToString(x.date)}</td>
        <td>{states[x.state]}</td>
        <td>
          <Link
            to={`/fahrten/${x._id}`}
            target="_blank"
            className="btn btn-sm btn-outline-info"
          >
            Details
          </Link>
        </td>
      </tr>
    ));

    return rows;
  };

  return isError ? (
    <FetchError />
  ) : (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Name/Firma</th>
            <th>Ort</th>
            <th>Adresse</th>
            <th>Fzg.</th>
            <th>Abfuhr (m³)</th>
            <th>Interv.</th>
            <th>Datum</th>
            <th>Status</th>
            <th>Auswahl</th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </table>
    </div>
  );
};

export default DashboardModalTable;
