import React from "react";
import { Redirect } from "react-router-dom";

import CustomerRides from "./Rides";
import FetchError from "../shared/FetchError";
import Form from "./form/Index";
import { baseUrl } from "../../utils/baseUrl";

class Edit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      isError: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleDelete() {
    try {
      const id = this.state.id;

      const url = `${baseUrl}/customers/${id}`;
      await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });

      this.setState({
        isDeleted: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async handleSubmit(customer) {
    try {
      const id = this.state.id;
      const url = `${baseUrl}/customers/${id}`;

      await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(customer),
      });

      this.setState({
        isSaved: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  redirect() {
    const isDeleted = this.state.isDeleted;
    const isSaved = this.state.isSaved;

    if (isDeleted || isSaved) {
      return <Redirect to="/" />;
    }
  }

  render() {
    const pageContent = this.state.isError ? (
      <FetchError />
    ) : (
      this.renderContent()
    );

    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Kundendaten bearbeiten</h1>
        </div>
        {pageContent}
      </div>
    );
  }

  renderContent() {
    return (
      <React.Fragment>
        <div className="mb-3">
          {this.renderForm()}
          <hr />
        </div>
        <div className="mb-5">
          <CustomerRides id={this.state.id} />
        </div>
      </React.Fragment>
    );
  }

  renderForm() {
    return (
      <React.Fragment>
        {this.redirect()}
        <Form
          id={this.state.id}
          handleDelete={this.handleDelete}
          handleSubmit={this.handleSubmit}
        />
      </React.Fragment>
    );
  }
}

export default Edit;
