import React from "react";

const NameInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="name">Name/Firma</label>
      <input
        type="text"
        id="name"
        className="form-control"
        value={props.name}
        onChange={props.handleChange}
        required={true}
      />
    </div>
  );
};

export default NameInput;
