import React from "react";

import OptionalInputTag from "./OptionalInputTag";

const MobileInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="mobile">Mobil</label>
      <OptionalInputTag />
      <input
        type="text"
        id="mobile"
        className="form-control"
        value={props.mobile}
        onChange={props.handleChange}
      />
    </div>
  );
};

export default MobileInput;
