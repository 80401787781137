import React from "react";

const StateFilter = (props) => {
  return (
    <div className="form-goup">
      <select
        id="customer"
        className="form-control"
        value={props.filter}
        onChange={props.handleFilter}
        disabled={props.disabled}
      >
        <option value={0}>Offen</option>
        <option value={1}>Erledigt</option>
        <option value={2}>Abgelehnt</option>
        <option value={3}>Alle</option>
        <option value={4}>Archiviert</option>
      </select>
    </div>
  );
};

export default StateFilter;
