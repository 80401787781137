import React from "react";

const ChartCard = (props) => {
  return (
    <div class="card w-100">
      <div class="card-body">
        <h5 class="card-title">{props.title}</h5>
        {props.children}
      </div>
    </div>
  );
};

export default ChartCard;
