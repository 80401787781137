import React from "react";

import OptionalInputTag from "./OptionalInputTag";

const LandlineInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="landline">Festnetz</label>
      <OptionalInputTag />
      <input
        type="text"
        id="landline"
        className="form-control"
        value={props.landline}
        onChange={props.handleChange}
      />
    </div>
  );
};

export default LandlineInput;
