import React from "react";

import {
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";

import { ocean } from "./ColorSchemes";

const renderCustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const LineChartVisualization = ({ stats }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={stats}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={renderCustomizedAxisTick} />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="gesamt"
          stroke={ocean.light}
          strokeWidth={2}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartVisualization;
