import React from "react";

const CustomerNotesInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="notes">Notizen</label>{" "}
      <small className="text-muted">(optional)</small>
      <textarea
        id="notes"
        className="form-control"
        rows="5"
        onChange={props.handleChange}
        value={props.notes}
      />
    </div>
  );
};

export default CustomerNotesInput;
