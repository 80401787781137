import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faCalendarWeek,
  faCheckCircle,
  faClock,
  faInfo,
  faReceipt,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";

import React from "react";
import { Link } from "react-router-dom";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { isToday, dateToString } from "../../../utils/dates";
import { states } from "../../../utils/states";

import "./Item.scss";

class ListItem extends React.PureComponent {
  render() {
    return (
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{this.renderHeading()}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{this.renderPanel()}</AccordionItemPanel>
      </AccordionItem>
    );
  }

  renderHeading() {
    const consumer = this.props.ride.consumer;
    const address = consumer.adress;

    const heading = (
      <div className="item-title">
        <span>
          {address.city}, {address.street} {this.renderDelayIndicator()}
          {this.renderCreatedTodayIndicator()}
          {this.renderHasInfoIndicator()}
        </span>
        <br />
        <span>{consumer.name}</span>
      </div>
    );

    return heading;
  }

  renderDelayIndicator() {
    const date = this.props.ride.date;
    const today = isToday(new Date(date));
    const delayIndicator = today ? (
      ""
    ) : (
      <FontAwesomeIcon fixedWidth={true} icon={faClock} />
    );

    return delayIndicator;
  }

  renderCreatedTodayIndicator() {
    const created = this.props.ride.created;
    const createdToday = isToday(new Date(created));
    const createdTodayIndicator = createdToday ? (
      <span className="badge badge-secondary">Neu</span>
    ) : (
      ""
    );

    return createdTodayIndicator;
  }

  renderHasInfoIndicator() {
    const infoIndicator = this.props.ride.rideNote ? (
      <FontAwesomeIcon fixedWidth={true} icon={faInfo} />
    ) : (
      ""
    );

    return infoIndicator;
  }

  renderPanel() {
    const ride = this.props.ride;

    const consumerNote = ride.consumer.notes;
    const date =
      typeof ride.date === "string"
        ? dateToString(ride.date)
        : ride.date.toLocaleDateString();

    const interval = ride.consumer.interval;
    const pitSize = ride.consumer.pitSize;
    const rideNote = ride.rideNote;
    const state = ride.state;
    const showDetails = this.props.showDetails;

    const today = isToday(new Date(ride.date));

    return (
      <table className="table item-preview mb-0">
        <tbody>
          {rideNote && rideNote.length > 0 && (
            <tr>
              <td className="preview-icon">
                <FontAwesomeIcon fixedWidth={true} icon={faInfo} />
              </td>
              <td>{rideNote}</td>
            </tr>
          )}
          <tr>
            <td className="preview-icon">
              <FontAwesomeIcon fixedWidth={true} icon={faCalendarDay} />
            </td>
            <td className={today ? "" : "text-danger"}>{date}</td>
          </tr>
          <tr>
            <td className="preview-icon">
              <FontAwesomeIcon fixedWidth={true} icon={faCheckCircle} />
            </td>
            <td>{states[state]}</td>
          </tr>
          <tr>
            <td>
              <b id="pit-size-icon">m³</b>
            </td>
            <td>{pitSize}</td>
          </tr>
          <tr>
            <td>
              <b id="preview-icon">
                <FontAwesomeIcon fixedWidth={true} icon={faCalendarWeek} />
              </b>
            </td>
            <td>{interval}</td>
          </tr>
          {consumerNote && consumerNote.length > 0 && (
            <tr>
              <td className="preview-icon">
                <FontAwesomeIcon fixedWidth={true} icon={faStickyNote} />
              </td>
              <td>{consumerNote}</td>
            </tr>
          )}
          {showDetails && (
            <tr>
              <td className="preview-icon">
                <FontAwesomeIcon fixedWidth={true} icon={faReceipt} />
              </td>
              <td>
                <Link
                  to={`/fahrten/${ride._id}/beleg`}
                  className="btn btn-sm btn-outline-primary"
                >
                  Anzeigen
                </Link>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

export default ListItem;
