import React from "react";

const OptionalInputTag = () => {
  return (
    <React.Fragment>
      {" "}
      <small className="text-muted">(optional)</small>
    </React.Fragment>
  );
};

export default OptionalInputTag;
