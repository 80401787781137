import { Link } from "react-router-dom";
import React from "react";

import FetchError from "../shared/FetchError";
import { baseUrl } from "../../utils/baseUrl";

class CreateUser extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      passwordValidation: "",
      role: "DRIVER",

      isError: false,
      isSaved: false,
      hasMaxUsers: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  async handleSubmit(e) {
    e.preventDefault();

    try {
      const { username, password, role } = this.state;
      const url = `${baseUrl}/users`;

      const user = {
        username,
        password,
        role,
      };

      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.status === 403) {
        this.setState({
          hasMaxUsers: true,
        });

        return;
      }

      this.setState({
        isSaved: true,
      });

      this.props.history.push("/benutzer");
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  render() {
    const pageContent = this.state.isError ? <FetchError /> : this.renderForm();

    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Neuen Benutzer anlegen</h1>
        </div>
        {this.renderMaxUserWarning()}
        {pageContent}
      </div>
    );
  }

  renderForm() {
    const { username, password, passwordValidation } = this.state;

    const disableSubmit = password === passwordValidation ? false : true;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label>Benutzername</label>
          <input
            type="text"
            className="form-control"
            id="username"
            placeholder="Benutzername"
            value={username}
            onChange={this.handleChange}
            required={true}
          />
        </div>

        <div className="form-group">
          <label htmlFor="role">Benutzerrolle</label>
          <select
            id="role"
            className="form-control"
            onChange={this.handleChange}
            value={this.state.role}
          >
            <option value="DRIVER">Fahrer</option>
            <option value="DISPATCHER">Disponent</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="password">Passwort</label>
          <input
            type="text"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            className="form-control"
            id="password"
            placeholder="Passwort eingeben"
            value={password}
            onChange={this.handleChange}
            required={true}
          />
          <small>
            Muss mindestens eine Zahl und einen Groß- und Kleinbuchstaben sowie
            mindestens 8 oder mehr Zeichen enthalten.
          </small>
        </div>
        <div className="form-group">
          <label htmlFor="passwordValidation">Passwort bestätigen</label>
          <input
            type="text"
            className="form-control"
            id="passwordValidation"
            placeholder="Passwort bestätigen"
            value={passwordValidation}
            onChange={this.handleChange}
            required={true}
          />
          <small>Muss dem eingegebenen Passwort entsprechen.</small>
        </div>
        <div className="d-flex">
          <div className="ml-auto">
            <button
              type="submit"
              className="btn btn-success mr-2"
              disabled={disableSubmit}
            >
              Benutzerdaten speichern
            </button>
            <Link to="/benutzer" className="btn btn-secondary mr-2">
              Zurück
            </Link>
          </div>
        </div>
      </form>
    );
  }

  renderMaxUserWarning() {
    if (!this.state.hasMaxUsers) {
      return;
    }

    return (
      <div className="alert alert-danger" role="alert">
        Die maximal Anzahl von verfügbaren Benutzerkonten wurde erreicht.
      </div>
    );
  }
}

export default CreateUser;
