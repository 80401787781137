import React from "react";

const CarSelect = (props) => {
  const cars = props.cars;

  const options = cars ? (
    cars.map((x, i) => (
      <option key={i + 1} value={x._id}>
        {x.license}
      </option>
    ))
  ) : (
    <option />
  );

  if (Array.isArray(options)) {
    options.unshift(
      <option key={0} value={""} selected={true} disabled={true}>
        Bitte wählen
      </option>
    );
  }

  const value = props.car ? props.car : "";

  const handleChange = (e) => {
    e.preventDefault();

    props.handleChange(e);
  };

  return (
    <div className="form-group">
      <label htmlFor="car">Fahrzeug</label>
      <select
        id="car"
        className="form-control"
        onChange={handleChange}
        value={value}
        required={true}
      >
        {options}
      </select>
    </div>
  );
};

export default CarSelect;
