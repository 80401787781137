import React from "react";

import { baseUrl } from "../utils/baseUrl";
import DatePicker from "../components/shared/DatePicker";

class RootAdministration extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      licenseExpiryDate: new Date(),
      maxUsers: "",
      isError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchClient = this.fetchClient.bind(this);
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  handleDateChange = (date) => {
    this.setState({
      licenseExpiryDate: date,
    });
  };

  async fetchClient() {
    try {
      const url = `${baseUrl}/client/`;

      const response = await fetch(url, {
        credentials: "include",
      });

      const result = await response.json();

      if (result.result === 0) {
        return;
      }

      const client = result;

      if (!client.licenseExpiryDate) {
        client.licenseExpiryDate = new Date();
      }

      this.setState({
        id: client._id,
        licenseExpiryDate: client.licenseExpiryDate,
        maxUsers: client.maxUsers,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    try {
      const id = this.state.id;
      const licenseExpiryDate = this.state.licenseExpiryDate;
      const maxUsers = this.state.maxUsers;

      const url = `${baseUrl}/client/license`;

      const message = {
        id,
        licenseExpiryDate,
        maxUsers,
      };

      await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      });

      this.setState({
        isSaved: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchClient();
  }

  render() {
    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Root Administration</h1>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="licenseExpiryDate">Lizenz gültig bis</label>
            <DatePicker
              date={this.state.licenseExpiryDate}
              onChange={this.handleDateChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="maxUers">Maximale Anzahl Benutzerkonten</label>
            <input
              type="number"
              id="maxUsers"
              className="form-control"
              onChange={this.handleChange}
              value={this.state.maxUsers}
            />
          </div>

          <button type="submit" className="btn btn-success">
            Speichern
          </button>
        </form>
      </div>
    );
  }
}

export default RootAdministration;
