import React from "react";

import DisposalAmountInput from "../../../shared/DisposalAmountInput";

const PitSizeInput = (props) => {
  return (
    <DisposalAmountInput
      id={"pitSize"}
      label={"Grubengröße"}
      value={props.pitSize}
      onChange={props.handleChange}
      required={true}
    />
  );
};

export default PitSizeInput;
