import React from "react";

import DatePicker from "../../../shared/DatePicker";

const DisposalDateInput = (props) => {
  const disposalDate = props.disposalDate;

  return (
    <div className="form-group">
      <label htmlFor="disposalDate">Abfuhr ändern</label>
      <DatePicker
        date={disposalDate}
        minDate={new Date()}
        onChange={props.handleChange}
      />
      <small className="text-muted">
        Datum nur ändern wenn Abfuhrdatum regelmäßig geändert werden soll.
      </small>
    </div>
  );
};

export default DisposalDateInput;
