import React from "react";
import { PieChart, Pie, ResponsiveContainer, Tooltip } from "recharts";

import { ocean } from "./ColorSchemes";

const PieChartVisualization = ({ vehicleStats }) => {
  const data = vehicleStats.map((x) => ({
    name: x.vehicle.license,
    value: x.shareTotal,
  }));

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 10) * cos;
    const my = cy + (outerRadius + 10) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * -2 // Adjust back to 22; to match original values
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >
          {data[index].name}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {data[index].value + "%"}
        </text>
      </g>
    );
  };

  return (
      <ResponsiveContainer width="100%" height={275}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            isAnimationActive={false}
            innerRadius={50}
            outerRadius={80}
            fill={ocean.light}
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
  );
};

export default PieChartVisualization;
