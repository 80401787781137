import "./Detail.scss";

import React from "react";

import DatePicker from "../shared/DatePicker";
import { baseUrl } from "../../utils/baseUrl";

function getStateBadge(state) {
  switch (state) {
    case 0:
      return <span class="badge badge-primary">Offen</span>;
    case 1:
      return <span class="badge badge-success">Erledigt</span>;
    case 2:
      return <span class="badge badge-danger">Abgelehnt</span>;
    case 4:
      return <span class="badge badge-dark">Archiviert</span>;
    default:
      return "";
  }
}

function getDateField(state, date) {
  switch (state) {
    case 0:
      return <span>Geplant: {date.toLocaleDateString()}</span>;
    case 1:
      return <span>Entleert: {date.toLocaleString("de-DE")}</span>;
    case 2:
      return <span>Abgelehnt: {date.toLocaleString("de-DE")}</span>;
    case 4:
      return <span>Entleert: {date.toLocaleString("de-DE")}</span>;
    default:
      return "";
  }
}

class RideDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      hoseLength: 0,
      quantity: 0,
      rideNote: "",
      readyToArchive: false,
      isError: false,
      isUpdated: false,
    };

    this.archiveRide = this.archiveRide.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.fetchClient = this.fetchClient.bind(this);
    this.fetchRide = this.fetchRide.bind(this);
    this.finishRide = this.finishRide.bind(this);
    this.rejectRide = this.rejectRide.bind(this);
    this.updateRide = this.updateRide.bind(this);
    this.toggleArchive = this.toggleArchive.bind(this);
  }

  toggleArchive(e) {
    e.preventDefault();

    this.setState((prevState) => ({
      readyToArchive: !prevState.readyToArchive,
    }));
  }

  handleCollapse(e) {
    e.preventDefault();

    const collapse = e.target.id;
    let collapseState = this.state[collapse];

    this.setState({
      [collapse]: !collapseState,
    });
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  handleDate = (date) => {
    this.setState({
      newDate: date,
    });
  };

  async fetchCars() {
    try {
      const response = await fetch(`${baseUrl}/cars/`, {
        credentials: "include",
      });
      const cars = await response.json();

      this.setState({
        cars,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchClient() {
    try {
      const url = `${baseUrl}/client`;
      const response = await fetch(url, {
        credentials: "include",
      });

      const result = await response.json();
      const client = result;

      this.setState({
        client,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchRide() {
    const id = this.props.match.params.id;

    try {
      const url = `${baseUrl}/rides/${id}`;
      const response = await fetch(url, {
        credentials: "include",
      });
      const result = await response.json();

      const {
        consumer,
        state,
        date,
        quantity,
        notes,
        car,
        reasonToDecline,
        reasonToDelegate,
        rideNote,
      } = result;

      const hoseLength = consumer.hoseLength ? consumer.hoseLength : 0;

      this.setState({
        car,
        consumer,
        date,
        hoseLength,
        notes,
        quantity,
        rideNote,
        reasonToDecline,
        reasonToDelegate,
        state,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async updateRide(e) {
    e.preventDefault();

    const state = this.state;
    const id = this.props.match.params.id;

    const ride = {
      _id: id,
      car: state.car,
      consumer: state.consumer,
      hoseLength: state.hoseLength,
      date: state.date,
      notes: state.notes,
      quantity: state.quantity,
      rideNote: state.rideNote,
      state: state.state,
    };

    if (this.state.newDate) {
      ride.date = this.state.newDate;
    }

    if (this.state.newCar) {
      ride.car = this.state.newCar;
    }

    try {
      const id = this.props.match.params.id;

      const url = `${baseUrl}/rides/${id}`;
      await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(ride),
      });

      this.setState({
        isUpdated: true,
      });

      this.fetchRide();
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  archiveRide(e) {
    e.preventDefault();

    this.setState(
      {
        state: 4,
      },
      () => {
        this.toggleArchive(e);
        this.updateRide(e);
      }
    );
  }

  finishRide(e) {
    e.preventDefault();

    let date = new Date();
    if (this.state.newDate) {
      date = this.state.newDate;
    }

    this.setState(
      {
        date: date,
        state: 1,
      },
      () => {
        this.updateRide(e);
        window.print();
      }
    );
  }

  rejectRide(e) {
    e.preventDefault();

    this.setState(
      {
        date: new Date(),
        state: 2,
      },
      () => {
        this.updateRide(e);
      }
    );
  }

  async componentDidMount() {
    await this.fetchRide();
    this.fetchCars();
    this.fetchClient();
  }

  render() {
    const state = this.state;
    const consumer = state.consumer;

    if (!consumer) {
      return <div className="container">No Consumer</div>;
    }

    return (
      <div className="container">
        <div className="pc-2 mt-4 mb-4 border-bottom d-flex">
          <h1>Abfuhrdetails</h1>
          <span className="ml-auto mt-2">
            <button
              className="btn btn-secondary"
              onClick={() => this.props.history.goBack()}
            >
              Zurück
            </button>
          </span>
        </div>

        {this.renderUpdateWarning()}
        {this.renderReceipt()}

        <div className="mt-3">
          {this.renderDoneButton()}
          {this.renderDeclineButton()}
          {this.renderChangeDateButton()}
          {this.renderChangeCarButton()}
          {this.renderAddNoteButton()}
          {this.renderArchiveButton()}
          {this.renderChangeAmountButton()}
        </div>

        <hr />
        {this.renderInteraction()}
      </div>
    );
  }

  renderReceipt() {
    const state = this.state;
    const consumer = state.consumer;
    const client = state.client;

    if (!client || !consumer) {
      return;
    }

    const isDone = state.state === 1 ? true : false;

    const dateObject = new Date(state.date);
    const dateField = getDateField(state.state, dateObject);

    return (
      <React.Fragment>
        <div className="print">
          <pre id="pre_print" className="d-one">
              <b>Entleerungsnachweis</b>{" "}
              <span className="ml-auto no-print">
                {getStateBadge(state.state)}
              </span>
              <br />
              {dateField} <br />
              <br />
              <b>Fuhrunternehmen</b> <br />
              {client.name} <br />
              {client.adress.street} <br />
              {client.adress.zipcode} {client.adress.city}{" "}
              {client.adress.district} <br />
              Tel.{/* /Fax  */} {client.landline} {/* / {client.fax} */} <br />
              <br />
              <b>Kunde</b> <br />
              Kd-Nr.{" "}
              {consumer.referenceNumber ? consumer.referenceNumber : "n/a"}{" "}
              <br />
              {consumer.name} <br />
              {consumer.adress.street} <br />
              {consumer.adress.zipcode} {consumer.adress.city} <br />
              {consumer.adress.district ? "OT" + consumer.adress.district : ""}
              <br />
              ---------------------------
              <br />
              {/* <b>Schlauchlänge:</b> {state.hoseLength} m<br /> */}
              <b>Abwassermenge:</b> {state.quantity} m³
              <br />
              ---------------------------
              <br />
              <b>Unterschrift Fahrer:</b> <br />
              <br />
              <br />
              <b>Unterschrift Kunde:</b> <br />
              <br />
              <br />
              <br />
            </pre>
        </div>
        <div>
          <b>Stammnotizen</b>
          <br />
          {consumer.notes ? consumer.notes : "Keine Notizen vorhanden"} <br />
          {this.renderRidenote()} <br />
          {this.renderReasonToDecline()}
          {this.renderReasontoDelegate()}
        </div>
      </React.Fragment>
    );
  }

  renderRidenote() {
    if (!this.state.rideNote) {
      return (
        <React.Fragment>
          <b>Abfuhrhinweise</b>
          <br />
          <span>Keine Abfuhrhinweise vorhanden</span>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <b>Abfuhrhinweise</b>
        <br />
        {this.state.rideNote}
      </React.Fragment>
    );
  }

  renderReasonToDecline() {
    if (!this.state.reasonToDecline) {
      return;
    }

    return (
      <div className="no-print">
        <b>Grund für Ablehnung</b> <br />
        {this.state.reasonToDecline}
      </div>
    );
  }

  renderReasontoDelegate() {
    if (!this.state.reasonToDelegate) {
      return;
    }

    return (
      <div className="no-print">
        <b>Grund für Übergabe</b> <br />
        {this.state.reasonToDelegate}
      </div>
    );
  }

  renderInteraction() {
    if (this.state.state === 1) {
      return this.renderDoneInteraction();
    }

    return this.renderAccordion();
  }

  renderDoneInteraction() {
    return (
      <React.Fragment>
        <div id="accordion">
          <div class="ride-collapse mt-3 mb-3">
            <div
              id="collapseChangeAmount"
              class="collapse"
              aria-labelledby="headingCollapseChangeAmount"
              data-parent="#accordion"
            >
              {this.renderChangeAmountForm()}
            </div>
          </div>
        </div>
        {this.renderPrintBluetooth()}
        {this.renderPrintReceipt()}
      </React.Fragment>
    );
  }

  renderAccordion() {
    return (
      <div id="accordion">
        <div class="ride-collapse mt-3 mb-3">
          <div
            id="collapseDone"
            class="collapse"
            aria-labelledby="headingCollapseDone"
            data-parent="#accordion"
          >
            {this.renderDoneForm()}
          </div>
        </div>

        <div class="ride-collapse mt-3 mb-3">
          <div
            id="collapseDecline"
            class="collapse"
            aria-labelledby="headingCollapseDecline"
            data-parent="#accordion"
          >
            {this.renderDeclineForm()}
          </div>
        </div>

        <div class="ride-collapse mt-3 mb-3">
          <div
            id="collapseChangeDate"
            class="collapse"
            aria-labelledby="headingCollapseChangeDate"
            data-parent="#accordion"
          >
            {this.renderChangeDateForm()}
          </div>
        </div>

        <div class="ride-collapse mt-3 mb-3">
          <div
            id="collapseChangeCar"
            class="collapse"
            aria-labelledby="headingCollapseChangeCar"
            data-parent="#accordion"
          >
            {this.renderChangeCarForm()}
          </div>
        </div>

        <div class="ride-collapse mt-3 mb-3">
          <div
            id="collapseAddNote"
            class="collapse"
            aria-labelledby="headingCollapseChangeNote"
            data-parent="#accordion"
          >
            {this.renderAddNoteForm()}
          </div>
        </div>
      </div>
    );
  }

  renderDoneButton() {
    const isDisabled =
      this.state.state === 1 ||
      this.state.state === 2 ||
      this.state.state === 4;

    return (
      <button
        class="btn btn-success mr-1"
        data-toggle="collapse"
        data-target="#collapseDone"
        aria-expanded="true"
        aria-controls="collapseDone"
        disabled={isDisabled}
      >
        Erledigen
      </button>
    );
  }

  renderDeclineButton() {
    const isDisabled =
      this.state.state === 1 ||
      this.state.state === 2 ||
      this.state.state === 4;

    return (
      <button
        class="btn btn-danger mr-1"
        data-toggle="collapse"
        data-target="#collapseDecline"
        aria-expanded="true"
        aria-controls="collapseDecline"
        disabled={isDisabled}
      >
        Ablehnen
      </button>
    );
  }

  renderChangeDateButton() {
    const isDisabled =
      this.state.state === 1 ||
      this.state.state === 2 ||
      this.state.state === 4;

    return (
      <button
        class="btn btn-primary mr-1"
        data-toggle="collapse"
        data-target="#collapseChangeDate"
        aria-expanded="true"
        aria-controls="collapseChangeDate"
        disabled={isDisabled}
      >
        Datum ändern
      </button>
    );
  }

  renderChangeCarButton() {
    const isDisabled =
      !navigator.onLine ||
      this.state.state === 1 ||
      this.state.state === 2 ||
      this.state.state === 4;

    return (
      <button
        class="btn btn-primary mr-1"
        data-toggle="collapse"
        data-target="#collapseChangeCar"
        aria-expanded="true"
        aria-controls="collapseChangeCar"
        disabled={isDisabled}
      >
        Fahrzeug ändern
      </button>
    );
  }

  renderAddNoteButton() {
    const isDisabled = this.state.state === 1 || this.state.state === 2;

    return (
      <button
        className="btn btn-secondary"
        data-toggle="collapse"
        data-target="#collapseAddNote"
        aria-expanded="true"
        aria-controls="collapseAddNote"
        disabled={isDisabled}
      >
        Hinweise hinzufügen
      </button>
    );
  }

  renderChangeAmountButton() {
    if (this.state.state !== 1) {
      return;
    }

    return (
      <button
        className="btn btn-secondary ml-1"
        data-toggle="collapse"
        data-target="#collapseChangeAmount"
        aria-expanded="true"
        aria-controls="collapseChangeAmount"
      >
        Menge ändern
      </button>
    );
  }

  renderArchiveButton() {
    if (this.state.state === 0) {
      return;
    }

    if (this.state.readyToArchive) {
      return (
        <React.Fragment>
          <button className="btn btn-danger ml-1" onClick={this.archiveRide}>
            Archivieren bestätigen
          </button>
          <button
            className="btn btn-secondary ml-1"
            onClick={this.toggleArchive}
          >
            Abbrechen
          </button>
        </React.Fragment>
      );
    }

    const isDisabled = this.state.state === 4;

    return (
      <button
        className="btn btn-danger ml-1"
        onClick={this.toggleArchive}
        disabled={isDisabled}
      >
        Abfuhr archivieren
      </button>
    );
  }

  printToBlueTooth() {
    const S = "#Intent;scheme=rawbt;";
    const P = "package=ru.a402d.rawbtprinter;end;";

    const printMessage = document.getElementById("pre_print").innerText;
    const textEncoded = encodeURI(printMessage);

    window.location.href = "intent:" + textEncoded + S + P;
  }

  renderPrintBluetooth() {
    return (
      <button
        class="btn btn-success btn-lg btn-block mt-3 mb-3"
        onClick={this.printToBlueTooth}
      >
        Abfuhrbeleg mobil drucken
      </button>
    );
  }

  renderPrintReceipt() {
    return (
      <button
        class="btn btn-secondary btn-lg btn-block mt-3 mb-3"
        onClick={window.print}
      >
        Abfuhrbeleg lokal drucken
      </button>
    );
  }

  renderDoneForm() {
    if (!this.state.consumer) {
      return "Kunde nicht gefunden";
    }

    const valueQuantity = this.state.quantity === 0 ? " " : this.state.quantity;
    const valueHoseLength =
      this.state.hoseLength === 0 ? " " : this.state.hoseLength;

    const date = this.state.newDate ? this.state.newDate : new Date();

    return (
      <form onSubmit={this.finishRide}>
        <h6>Erledigung bestätigen</h6>
        <div className="form-group">
          <label htmlFor="quantity">Menge in m³</label>
          <input
            className="form-control"
            id="quantity"
            type="number"
            min={0.5}
            step={0.5}
            max={this.state.consumer.pitSize}
            value={valueQuantity}
            onChange={this.handleChange}
            required={true}
          />
        </div>
        <div className="form-group">
          <label htmlFor="quantity">Schlauchlänge in m</label>

          <input
            className="form-control"
            id="hoseLength"
            type="number"
            min={0.5}
            step={0.5}
            value={valueHoseLength}
            onChange={this.handleChange}
            required={true}
          />
          <small className="text-muted">
            Basierend auf letzter erfasster Schlauchlänge
          </small>
        </div>
        <div className="form-group">
          <label htmlFor="changeDoneDate">Datum Erledigung ändern</label>
          <DatePicker date={date} onChange={this.handleDate} />
        </div>
        <button type="submit" className="btn btn-success mr-1">
          Bestätigen
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          data-toggle="collapse"
          data-target="#collapseDone"
          aria-expanded="true"
          aria-controls="collapseDone"
        >
          Abbrechen
        </button>
      </form>
    );
  }

  renderAddNoteForm() {
    return (
      <form onSubmit={this.updateRide}>
        <h6>Abfuhrhinweis hinzufügen</h6>
        <textarea
          id="rideNote"
          className="form-control mb-3"
          rows="5"
          onChange={this.handleChange}
          value={this.state.rideNote}
        />

        <button
          type="submit"
          className="btn btn-success mr-1"
          data-toggle="collapse"
          data-target="#collapseAddNote"
          aria-expanded="true"
          aria-controls="collapseAddNote"
        >
          Bestätigen
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          data-toggle="collapse"
          data-target="#collapseAddNote"
          aria-expanded="true"
          aria-controls="collapseAddNote"
        >
          Abbrechen
        </button>
      </form>
    );
  }

  renderChangeAmountForm() {
    return (
      <form onSubmit={this.updateRide}>
        <h6>Abfuhrmenge ändern</h6>
        <div className="form-group">
          <label htmlFor="quantity">Menge in m³</label>
          <input
            className="form-control"
            id="quantity"
            type="number"
            min={0.5}
            step={0.5}
            max={this.state.consumer.pitSize}
            value={this.state.quantity}
            onChange={this.handleChange}
            required={true}
          />
        </div>

        <button
          type="submit"
          className="btn btn-success mr-1"
          data-toggle="collapse"
          data-target="#collapseChangeAmount"
          aria-expanded="true"
          aria-controls="collapseChangeAmount"
        >
          Bestätigen
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          data-toggle="collapse"
          data-target="#collapseChangeAmount"
          aria-expanded="true"
          aria-controls="collapseChangeAmount"
        >
          Abbrechen
        </button>
      </form>
    );
  }

  renderDeclineForm() {
    return (
      <form onSubmit={this.rejectRide}>
        <h6>Ablehnen bestätigen</h6>
        <button
          type="submit"
          className="btn btn-danger mr-1"
          data-toggle="collapse"
          data-target="#collapseDecline"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          Bestätigen
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          data-toggle="collapse"
          data-target="#collapseDecline"
          aria-expanded="true"
          aria-controls="collapseDecline"
        >
          Abbrechen
        </button>
      </form>
    );
  }

  renderChangeCarForm() {
    if (!this.state.cars) {
      return;
    }

    const cars = this.state.cars;

    const newCar = this.state.newCar;
    let car = this.state.car;
    if (newCar) {
      car = cars.find((x) => x._id === this.state.newCar);
    }

    const options = cars.map((x, i) => (
      <option key={i} value={x._id}>
        {x.license}
      </option>
    ));

    const confirmDisabled = this.state.newCar ? false : true;

    return (
      <React.Fragment>
        <h6>Anderes Fahrzeug auswählen</h6>
        <div className="form-group">
          <label htmlFor="newCar">Fahrzeug</label>
          <select
            id="newCar"
            className="form-control"
            onChange={this.handleChange}
            value={car._id}
          >
            {options}
          </select>
        </div>
        <button
          className="btn btn-success mr-1"
          data-toggle="collapse"
          data-target="#collapseChangeCar"
          aria-expanded="true"
          aria-controls="collapseChangeCar"
          onClick={this.updateRide}
          disabled={confirmDisabled}
        >
          Bestätigen
        </button>
        <button
          className="btn btn-secondary"
          data-toggle="collapse"
          data-target="#collapseChangeCar"
          aria-expanded="true"
          aria-controls="collapseChangeCar"
        >
          Abbrechen
        </button>
      </React.Fragment>
    );
  }

  renderChangeDateForm() {
    const date = this.state.newDate ? this.state.newDate : this.state.date;

    return (
      <React.Fragment>
        <h6>Neues Datum für Abfuhr auswählen</h6>
        <div className="form-group">
          <DatePicker date={date} onChange={this.handleDate} />
        </div>
        <button
          className="btn btn-success mr-1"
          data-toggle="collapse"
          data-target="#collapseChangeDate"
          aria-expanded="true"
          aria-controls="collapseOne"
          onClick={this.updateRide}
        >
          Bestätigen
        </button>
        <button
          className="btn btn-secondary"
          data-toggle="collapse"
          data-target="#collapseChangeDate"
          aria-expanded="true"
          aria-controls="collapseChangeDate"
        >
          Abbrechen
        </button>
      </React.Fragment>
    );
  }

  renderUpdateWarning() {
    if (!this.state.isUpdated) {
      return;
    }

    return (
      <div class="alert alert-success mt-3" role="alert">
        Entsorgungsfahrt wurde aktualisiert.
      </div>
    );
  }
}

export default RideDetails;
