import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

import BarChartVisualization from "./charts/BarChart";
import { baseUrl } from "../../utils/baseUrl";
import Card from "./Card";
import Modal from "./modal/Index";
import MultiLineChartVisualization from "./charts/MultiLineChart";
import SingleLineChartVisualization from "./charts/SingleLineChart";
import PieChartVisualization from "./charts/PieChart";
import RadialBarChartVisualization from "./charts/RadialBarChart";

import "./index.scss";

const Dashboard = () => {
  const [total, setTotal] = useState({
    countCarriedOver: "",
    countDone: "0",
    countOpen: "0",
    countTotal: "0",
    percentageDone: "0",
  });
  const [vehicles, setVehicles] = useState([]);
  const [periodTotal, setPeriodTotal] = useState([]);
  const [periodVehicles, setPeriodVehicles] = useState([]);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalRideState, setModalRideState] = useState();

  useEffect(() => {
    fetchTotal();
    fetchVehicles();
    fetchPeriodtotal();
    fetchPeriodVehicles();
  }, []);

  function openModal(state) {
    setModalRideState(state);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const fetchTotal = async () => {
    const url = `${baseUrl}/dashboard/day/total`;
    const response = await fetch(url, {
      credentials: "include",
    });

    const total = await response.json();
    setTotal(total);
  };

  const fetchVehicles = async () => {
    const url = `${baseUrl}/dashboard/day/vehicles`;
    const response = await fetch(url, {
      credentials: "include",
    });

    const vehicles = await response.json();
    setVehicles(vehicles);
  };

  const fetchPeriodtotal = async () => {
    const url = `${baseUrl}/dashboard/period/total`;
    const response = await fetch(url, {
      credentials: "include",
    });

    const result = await response.json();
    setPeriodTotal(result);
  };

  const fetchPeriodVehicles = async () => {
    const url = `${baseUrl}/dashboard/period/vehicles`;
    const response = await fetch(url, {
      credentials: "include",
    });

    const result = await response.json();
    setPeriodVehicles(result);
  };

  const date = new Date();

  return (
    <div className="container-fluid">
      <Modal
        modalRideState={modalRideState}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
      />
      <div className="pb-2 mt-4 border-bottom">
        <h2>Dashboard {date.toLocaleDateString()}</h2>
      </div>

      <div className="row mt-3">
        <div className="col-md-3 font-2em">
          <Card title={"Erledigt gesamt"}>
            <RadialBarChartVisualization
              percentageDone={total.percentageDone}
            />
          </Card>
        </div>

        <div className="col-md-1">
          <div className="row mb-1">
            <Card
              title={
                <p
                  id="total-tool-tip"
                  data-tip="Status: Offen, Erledigt, Abgelehnt"
                >
                  Gesamt
                  <span className="ml-2">
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      fixedWidth={true}
                    />
                  </span>
                </p>
              }
            >
              <div className="d-flex">
                <span className="font-1-5em">{total.countTotal}</span>
                <span className="ml-auto"></span>
              </div>
              <ReactTooltip />
            </Card>
          </div>
          <div className="row mb-1">
            <Card title={"Offen"}>
              <a
                href="#link"
                className="font-1-5em"
                role="button"
                onClick={() => openModal(0)}
              >
                {total.countOpen}
              </a>
            </Card>
          </div>
          <div className="row">
            <Card title={"Erledigt"}>
              <a
                href="#link"
                className="font-1-5em"
                role="button"
                onClick={() => openModal(1)}
              >
                {total.countDone}
              </a>
            </Card>
          </div>
        </div>

        <div className="col-md-5">
          <Card title={"Anzahl je Fahrzeug"}>
            <BarChartVisualization vehicleStats={vehicles} />
          </Card>
        </div>
        <div className="col-md-3">
          <Card title={"Anteil erledigt je Fahrzeug"}>
            <PieChartVisualization vehicleStats={vehicles} />
          </Card>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <Card title={"Erledigt gesamt letzte 30 Tage"}>
            <SingleLineChartVisualization stats={periodTotal} />
          </Card>
        </div>
        <div className="col-md-6">
          <Card title={"Erledigt je Fahrzeug letzte 30 Tage"}>
            <MultiLineChartVisualization stats={periodVehicles} />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
