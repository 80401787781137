import React from "react";
import CSVReader from "react-csv-reader";

import TaskPanel from "../shared/TaskPanel";
import { baseUrl } from "../../utils/baseUrl";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  // transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};

class ImportCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cars: [],
      data: [],
      dataUploaded: 0,
      fileInfo: "",

      isError: false,
      isSaved: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForce = this.handleForce.bind(this);
  }

  handleForce(data, fileInfo) {
    let year;
    let month;
    let day;
    let arr;

    var date = new Date();
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    new Date(now_utc);

    for (const d of data) {
      arr = d.startDate.split(".");

      day = parseInt(arr[0]);
      month = parseInt(arr[1] - 1); // get month as index, 0 to 11
      year = parseInt(arr[2]);

      d.startDate = new Date(Date.UTC(year, month, day, 0 + 2, 0, 0)); // +2 hours to avoid shift to prior day

      if (d.interval === 800) {
        d.interval = 0;
      }
    }

    this.setState({
      data,
      fileInfo,
    });
  }

  async fetchCars() {
    try {
      const response = await fetch(`${baseUrl}/cars`, {
        credentials: "include",
      });

      const cars = await response.json();

      this.setState({
        cars,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    try {
      const cars = this.state.cars;
      const data = this.state.data;

      for (const d of data) {
        if (d.city === null) {
          continue;
        }

        const customer = {
          company: "",
          name: d.name,
          referenceNumber: d.referenceNumber,
          adress: {
            city: d.city,
            district: "",
            street: d.street,
            zipcode: d.zipCode,
          },
          contact: {
            landline: "",
            mobile: "",
            fax: "",
            mail: "",
          },
          consumptionSite: "",
          startDate: d.startDate,
          tavArea: d.tavArea,
          pitSize: parseFloat(d.pitSize),
          interval: d.interval,
          car: d.driver === 1 ? cars[0]._id : cars[1]._id,
          notes: d.notes,
        };

        const url = `${baseUrl}/customers/import`;
        await fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(customer),
        });

        this.setState({
          dataUploaded: ++this.state.dataUploaded,
        });
      }

      this.setState({
        isSaved: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchCars();
  }

  render() {
    const readyToUpload = this.state.data.length > 0 ? true : false;
    const isSaved = this.state.isSaved;

    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Kundendaten Importieren</h1>
        </div>

        {this.renderUploadProgress()}
        {this.renderUploadResult()}

        <TaskPanel>
          <div className="d-flex">
            <button
              className="btn btn-primary mr-2"
              onClick={this.handleSubmit}
              disabled={!readyToUpload || isSaved}
            >
              Daten speichern
            </button>
            <CSVReader
              cssClass="react-csv-input"
              // label="Kundendaten in CSV Format auswählen"
              onFileLoaded={this.handleForce}
              parserOptions={papaparseOptions}
            />
          </div>
        </TaskPanel>

        <div className="pb-2 mt-4 border-bottom d-flex">
          <h3 className="mr-5">Vorschau</h3>

          {this.renderFileInfo()}
        </div>

        {this.renderImported()}
      </div>
    );
  }
  renderFileInfo() {
    if (this.state.fileInfo.length === 0) {
      return;
    }

    const fileInfo = this.state.fileInfo;

    return (
      <div className="d-flex mt-auto">
        <small className="mr-3">
          <b>Dateiname:</b> {fileInfo.name}{" "}
        </small>
        <small className="mr-3">
          <b>Größe:</b> {fileInfo.size} bytes{" "}
        </small>
        <small className="mr-3">
          <b>Anzahl Datensätze:</b> {this.state.data.length}
        </small>
      </div>
    );
  }

  renderImported() {
    const c = this.state.data;

    const tBodyRows = c.map((x, i) => (
      <tr key={i}>
        <td>{x.referenceNumber}</td>
        <td>{x.name}</td>
        <td>{x.street}</td>
        <td>{x.zipCode}</td>
        <td>{x.city}</td>
        <td>{x.tavArea}</td>
        <td>{x.pitSize}</td>
        <td>{new Date(x.startDate).toLocaleDateString()}</td>
        <td>{x.interval}</td>
      </tr>
    ));

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Kundennummer</th>
            <th>Name</th>
            <th>Straße</th>
            <th>PLZ</th>
            <th>Ort</th>
            <th>TAV Gebiet</th>
            <th>Größe m³</th>
            <th>Start</th>
            <th>Interval</th>
          </tr>
        </thead>
        <tbody>{tBodyRows}</tbody>
      </table>
    );
  }

  renderUploadProgress() {
    if (this.state.dataUploaded > 0) {
      return (
        <div className="alert alert-info mt-3" role="alert">
          Import läuft... {this.state.dataUploaded} Datensätze importiert.
        </div>
      );
    }
  }

  renderUploadResult() {
    if (!this.state.isSaved) {
      return;
    }

    const uploadResult = this.state.isError ? (
      <div className="alert alert-danger mt-3" role="alert">
        {this.state.dataUploaded} Kundendaten erfolgreich importiert.
      </div>
    ) : (
      <div className="alert alert-success mt-3" role="alert">
        Import abgeschlossen... {this.state.dataUploaded} Kundendaten
        erfolgreich importiert.
      </div>
    );

    return uploadResult;
  }
}

export default ImportCustomer;
