import { Link } from "react-router-dom";
import React from "react";

import FetchError from "../shared/FetchError";
import TaskPanel from "../shared/TaskPanel";
import { baseUrl } from "../../utils/baseUrl";
import { dateToString } from "../../utils/dates";

function getRole(role) {
  switch (role) {
    case "DISPATCHER":
      return "Disponent";

    case "DRIVER":
      return "Fahrer";

    default:
      return "";
  }
}

class UserList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      isError: false,
    };

    this.refresh = this.refresh.bind(this);
  }

  async refresh() {
    try {
      const url = `${baseUrl}/users`;

      const response = await fetch(url, {
        credentials: "include",
      });

      const list = await response.json();

      this.setState({
        list,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async componentDidMount() {
    await this.refresh();
  }

  render() {
    const pageContent = this.state.isError ? <FetchError /> : this.renderList();

    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h2>Benutzer</h2>
        </div>

        <TaskPanel>
          <Link to="/benutzer/bearbeiten" className="btn btn-primary">
            Benutzer anlegen
          </Link>
        </TaskPanel>

        {pageContent}
      </div>
    );
  }

  renderList() {
    if (!this.state.list) {
      return;
    }

    const list = this.state.list;

    const mappedList = list.map((x, i) => (
      <tr key={`user-table-row-${i}`}>
        <td>{x.username}</td>
        <td>{getRole(x.role)}</td>
        <td>{dateToString(x.created_at)}</td>
        <td>{dateToString(x.updated_at)}</td>
        <td>
          <Link
            to={`/benutzer/bearbeiten/${x._id}`}
            className="btn btn-primary"
          >
            Anzeigen
          </Link>
        </td>
      </tr>
    ));

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Benutzername</th>
            <th>Rolle</th>
            <th>Angelegt</th>
            <th>Letzte Aktualisierung</th>
            <th>Auswahl</th>
          </tr>
        </thead>
        <tbody>{mappedList}</tbody>
      </table>
    );
  }
}

export default UserList;
