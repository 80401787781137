import React from "react";
import { Redirect } from "react-router-dom";

import FetchError from "../shared/FetchError";
import Form from "./form/Index";
import { baseUrl } from "../../utils/baseUrl";

class Create extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(customer) {
    try {
      const url = `${baseUrl}/customers/`;

      await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(customer),
      });

      this.setState({
        isSaved: true,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  redirect() {
    const isSaved = this.state.isSaved;

    if (isSaved) {
      return <Redirect to="/" />;
    }
  }

  render() {
    const pageContent = this.state.isError ? <FetchError /> : this.renderForm();

    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Neuen Kunden anlegen</h1>
        </div>
        {pageContent}
      </div>
    );
  }

  renderForm() {
    return (
      <React.Fragment>
        {this.redirect()}
        <Form handleSubmit={this.handleSubmit} />
      </React.Fragment>
    );
  }
}

export default Create;
