import React from "react";
import { Link } from "react-router-dom";

import CarSelect from "./CarSelect";
import CityInput from "./input/CityInput";
import ConsumptionSiteInput from "./input/ConsumptionSite";
import StartDateInput from "./input/StartDateInput";
import DistrictInput from "./input/DistrictInput";
import DisposalDateInput from "./input/DisposalDateInput";
import FaxInput from "./input/FaxInput";
import IntervalInput from "./input/IntervalInput";
import LandlineInput from "./input/LandlineInput";
import MailInput from "./input/MailInput";
import MobileInput from "./input/MobileInput";
import NameInput from "./input/NameInput";
import NotesInput from "./input/NotesInput";
import PitSizeInput from "./input/PitSizeInput";
import ReferenceNumberInput from "./input/ReferenceNumberInput";
import StreetInput from "./input/StreetInput";
import TavAreaInput from "./input/TavAreaInput";
import ZipInput from "./input/ZipInput";
import { baseUrl } from "./../../../utils/baseUrl";

class CustomerForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disposalDate: new Date(),
      startDate: new Date(),
      isReadyToDelete: false,
    };

    this.delete = this.delete.bind(this);
    this.handleDisposalDate = this.handleDisposalDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  handleDisposalDate = (date) => {
    this.setState({
      changeDisposalDate: true,
      disposalDate: date,
    });
  };

  handleStartDate = (date) => {
    this.setState({
      startDate: date,
    });
  };

  delete(e) {
    e.preventDefault();

    this.props.handleDelete();
  }

  async fetchCars() {
    try {
      const response = await fetch(`${baseUrl}/cars`, {
        credentials: "include",
      });

      const cars = await response.json();

      this.setState({
        cars,
      });
      
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchCustomer() {
    if (!this.props.id) {
      return;
    }

    try {
      const id = this.props.id;

      const response = await fetch(`${baseUrl}/customers/${id}`, {
        credentials: "include",
      });
      const result = await response.json();

      let customer = { ...result };
      delete customer.adress;
      delete customer.contact;
      customer = { ...customer, ...result.adress, ...result.contact };

      this.setState(customer);
      
    } catch (error) {
      this.setState({
        isError: true,
      });
    }
  }

  submit(e) {
    e.preventDefault();

    const state = this.state;

    if (!state.car || this.state.car === "") {
      return;
    }

    const customer = {
      referenceNumber: state.referenceNumber,
      name: state.name,

      adress: {
        city: state.city,
        district: state.district,
        street: state.street,
        zipcode: state.zipcode,
      },

      contact: {
        landline: state.landline,
        mobile: state.mobile,
        fax: state.fax,
        mail: state.mail,
      },

      consumptionSite: state.consumptionSite,
      startDate: state.startDate,
      tavArea: state.tavArea,
      pitSize: state.pitSize,
      changeDisposalDate: state.changeDisposalDate,
      disposalDate: state.disposalDate,
      interval: state.interval,
      car: state.car,
      notes: state.notes,
    };

    this.props.handleSubmit(customer);
  }

  toggleDelete(e) {
    e.preventDefault();

    this.setState((prevState) => ({
      isReadyToDelete: !prevState.isReadyToDelete,
    }));
  }

  componentDidMount() {
    this.fetchCustomer();
    this.fetchCars();
  }

  render() {
    return (
      <form onSubmit={this.submit}>
        {this.renderBaseData()}
        {this.renderAddressData()}
        {this.renderContactData()}
        {this.renderDisposalData()}
        {this.renderNotes()}
        {this.renderButtons()}
      </form>
    );
  }

  renderAddressData() {
    return (
      <div id="customer-address-data-input">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h3>Adresse</h3>
        </div>
        <StreetInput
          street={this.state.street}
          handleChange={this.handleChange}
        />
        <ZipInput
          zipcode={this.state.zipcode}
          handleChange={this.handleChange}
        />
        <CityInput city={this.state.city} handleChange={this.handleChange} />
        <DistrictInput
          district={this.state.district}
          handleChange={this.handleChange}
        />
      </div>
    );
  }

  renderBaseData() {
    return (
      <div id="customer-base-data-input">
        <ReferenceNumberInput
          referenceNumber={this.state.referenceNumber}
          handleChange={this.handleChange}
        />
        <NameInput name={this.state.name} handleChange={this.handleChange} />
      </div>
    );
  }

  renderContactData() {
    return (
      <div id="customer-contact-data-input">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h3>Kontaktdaten</h3>
        </div>
        <LandlineInput
          landline={this.state.landline}
          handleChange={this.handleChange}
        />
        <MobileInput
          mobile={this.state.mobile}
          handleChange={this.handleChange}
        />
        <FaxInput fax={this.state.fax} handleChange={this.handleChange} />
        <MailInput mail={this.state.mail} handleChange={this.handleChange} />
      </div>
    );
  }

  renderDisposalData() {
    return (
      <div id="customer-disposal-data-input">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h3>Entsorgungsdaten</h3>
        </div>
        <StartDateInput
          id={this.props.id}
          startDate={this.state.startDate}
          handleChange={this.handleStartDate}
        />
        <TavAreaInput
          tavArea={this.state.tavArea}
          handleChange={this.handleChange}
        />
        <ConsumptionSiteInput
          consumptionSite={this.state.consumptionSite}
          handleChange={this.handleChange}
        />
        <PitSizeInput
          pitSize={this.state.pitSize}
          handleChange={this.handleChange}
        />
        {this.props.id && (
          <DisposalDateInput
            disposalDate={this.state.disposalDate}
            handleChange={this.handleDisposalDate}
          />
        )}
        <IntervalInput
          id={this.props.id}
          interval={this.state.interval}
          handleChange={this.handleChange}
        />
        <CarSelect
          car={this.state.car}
          cars={this.state.cars}
          handleChange={this.handleChange}
        />
      </div>
    );
  }

  renderNotes() {
    return (
      <div id="customer-notes-input">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h3>Kundenotizen</h3>
        </div>
        <NotesInput notes={this.state.notes} handleChange={this.handleChange} />
      </div>
    );
  }

  renderButtons() {
    const isReadyToDelete = this.state.isReadyToDelete;
    const deleteButton = isReadyToDelete ? (
      <React.Fragment>
        <button className="btn btn-danger mr-2" onClick={this.delete}>
          Kundendaten löschen
        </button>
        <button className="btn btn-success mr-2" onClick={this.toggleDelete}>
          Abbrechen
        </button>
      </React.Fragment>
    ) : (
      <button className="btn btn-danger mr-2" onClick={this.toggleDelete}>
        Kundendaten löschen
      </button>
    );

    return (
      <div className="d-flex mt-3">
        {deleteButton}
        <div className="ml-auto">
          <button
            type="submit"
            id="submit-customer-data"
            className="btn btn-success mr-2"
          >
            Kundendaten speichern
          </button>
          <Link to="/" className="btn btn-secondary mr-2">
            Zurück
          </Link>
        </div>
      </div>
    );
  }
}

export default CustomerForm;
