import React from "react";

const StreetInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="street">Strasse & Hausnummer</label>
      <input
        type="text"
        className="form-control"
        id="street"
        value={props.street}
        onChange={props.handleChange}
        required={true}
      />
    </div>
  );
};

export default StreetInput;
