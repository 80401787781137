export function dateToString(date) {
  const years = +date.split("-")[0];
  const month = +date.split("-")[1] - 1; // month accepts index 0 to 11, (1 to 12) - 1
  const day = +date.split("-")[2].substring(0, 2);

  const utcDateObject = new Date(Date.UTC(years, month, day));

  return utcDateObject.toLocaleDateString();
}

export function isToday(date) {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}
