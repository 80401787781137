import { Link } from "react-router-dom";
import React from "react";

import StateFilter from "../shared/StateFilter";
import DatePicker from "../shared/DatePicker";
import { dateToString } from "../../utils/dates";
import { states } from "../../utils/states";
import { baseUrl } from "../../utils/baseUrl";

class CustomerRides extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filter: 3,
      from: new Date(),
      to: new Date(),
      rides: [],
      isError: false,
    };

    this.handleFilter = this.handleFilter.bind(this);
    this.handleFrom = this.handleFrom.bind(this);
    this.handleTo = this.handleTo.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  handleFilter(e) {
    e.preventDefault();

    this.setState({
      filter: e.target.value,
    });
  }

  handleFrom = (from) => {
    this.setState(
      {
        from,
      },
      () => this.refresh()
    );
  };

  handleTo = (to) => {
    this.setState(
      {
        to,
      },
      () => this.refresh()
    );
  };

  async refresh() {
    const id = this.props.id;

    const from = this.state.from;
    const to = this.state.to;

    if (!from || !to) {
      return;
    }

    const fromUrl = `${from.getFullYear()}-${
      from.getMonth() + 1
    }-${from.getDate()}`;
    const toUrl = `${to.getUTCFullYear()}-${to.getMonth() + 1}-${to.getDate()}`;

    const query = `from=${fromUrl}&to=${toUrl}&id=${id}`;

    try {
      const response = await fetch(`${baseUrl}/rides/customer?${query}`, {
        credentials: "include",
      });
      const result = await response.json();

      this.setState({
        rides: result.rides,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.refresh();
  }

  render() {
    return (
      <div id="customerRides">
        {this.renderPanel()}
        {this.renderRides()}
      </div>
    );
  }

  renderPanel() {
    return (
      <div className="d-flex mb-3">
        <h4 className="mr-3">Entsorgungsfahrten nach Status</h4>
        <span className="mr-1">
          <DatePicker date={this.state.from} onChange={this.handleFrom} />
        </span>
        <span className="mr-1">
          <DatePicker date={this.state.to} onChange={this.handleTo} />
        </span>
        <StateFilter
          filter={this.state.filter}
          handleFilter={this.handleFilter}
        />
      </div>
    );
  }

  renderRides() {
    if (!this.state.rides) {
      return;
    }

    const filter = parseInt(this.state.filter);
    let rides = this.state.rides;

    if (filter !== 3) {
      rides = rides.filter((x) => x.state === filter);
    }

    if (rides.length === 0) {
      return (
        <p className="text-warning text-center mt-4">
          Für den gewählten Zeitraum oder Status sind keine Daten vorhanden.
        </p>
      );
    }

    rides = rides.sort((a, b) => a.date.localeCompare(b.date));

    const elements = rides.map((x, i) => (
      <React.Fragment>
        <tr id={i}>
          <td>{dateToString(x.date)}</td>
          <td>{states[x.state]}</td>
          <td>
            <Link to={`/fahrten/${x._id}`} className="btn btn-primary">
              Ansehen
            </Link>
          </td>
        </tr>
      </React.Fragment>
    ));

    return (
      <React.Fragment>
        <div className="table">
          <table className="table">
            <thead>
              <th>Datum</th>
              <th>Status</th>
              <th>Auswahl</th>
            </thead>
            <tbody>{elements}</tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomerRides;
