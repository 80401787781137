import { Link } from "react-router-dom";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import FetchError from "../shared/FetchError";
import SearchInput from "../shared/SearchInput";
import TaskPanel from "../shared/TaskPanel";
import { baseUrl } from "../../utils/baseUrl";

class CustomerList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      list: [],
      page: 1,
      query: "",
    };

    this.extendList = this.extendList.bind(this);
    this.incrementPage = this.incrementPage.bind(this);
    this.refresh = this.refresh.bind(this);
    this.query = this.query.bind(this);
  }
  // TODO: When user queries, and sets query to 0,
  // stale query results remain in state.
  // Extension of search results.
  extendList(nextPage) {
    const currentList = this.state.list;
    const updatedList = [...currentList, ...nextPage];

    this.setState({
      list: updatedList,
    });

    this.incrementPage();
  }

  incrementPage() {
    this.setState((prevState) => ({
      page: ++prevState.page,
    }));
  }

  async refresh() {
    const query = this.state.query;
    const page = this.state.page;

    try {
      const url = `${baseUrl}/customers/list/?value=${query}&page=${page}`;
      const response = await fetch(url, {
        credentials: "include",
      });
      const result = await response.json();

      query.length > 0
        ? this.setState({ list: result })
        : this.extendList(result);
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  query(e) {
    e.preventDefault();

    this.setState(
      {
        query: e.target.value,
      },
      () => this.refresh()
    );
  }

  componentDidMount() {
    this.refresh();
  }

  render() {
    const isError = this.state.isError;
    const query = this.state.query;

    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h2>Kunden</h2>
        </div>

        <TaskPanel>
          <Link className="btn btn-primary" to="/bearbeiten">
            Kunden anlegen
          </Link>
        </TaskPanel>
        <SearchInput
          onChange={this.query}
          onSearch={this.refresh}
          query={query}
        />
        {isError ? <FetchError /> : this.renderList()}
      </div>
    );
  }

  renderList() {
    const list = this.state.list;
    const query = this.state.query;

    if (!list[0] && query.length > 0) {
      return (
        <div className="alert alert-info text-center" role="alert">
          Zu dem angegebenen Suchbgeriff konnte kein passendes Ergebnis gefunden
          werden.
          {this.state.errorMessage}
        </div>
      );
    }

    const rows = list.map((x, i) => (
      <tr key={`custommer-table-row-${i}`}>
        <td>{x.company ? x.company : x.name}</td>
        <td>{x.adress.street}</td>
        <td>{x.adress.zipcode}</td>
        <td>{x.adress.city}</td>
        <td>{x.pitSize}</td>
        <td>{x.interval}</td>
        <td>
          <Link to={`/bearbeiten/${x._id}`} className="btn btn-primary">
            Anzeigen
          </Link>
        </td>
      </tr>
    ));

    return (
      <InfiniteScroll
        dataLength={rows.length}
        next={this.refresh}
        hasMore={query.length > 0 ? false : true}
        style={{ overflow: "inherit" }}
        loader={<h4>Kundendaten laden...</h4>}
      >
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name/Firma</th>
              <th>Straße & Hausnummer</th>
              <th>Postleitzahl</th>
              <th>Ort</th>
              <th>m³</th>
              <th>Intervall</th>
              <th>Auswahl</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </InfiniteScroll>
    );
  }
}

export default CustomerList;
