import React from "react";

import OptionalInputTag from "./OptionalInputTag";

const MailInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="company">E-Mail</label>
      <OptionalInputTag />
      <input
        type="text"
        id="mail"
        className="form-control"
        value={props.mail}
        onChange={props.handleChange}
      />
    </div>
  );
};

export default MailInput;
