import { Link } from "react-router-dom";
import React from "react";

import FetchError from "../shared/FetchError";
import { baseUrl } from "../../utils/baseUrl";

class EditCar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      driver: "",
      drivers: [],
      name: "",
      manufacturer: "",
      license: "",
      size: "",

      diverIsEngaged: false,
      hasCustomer: false,

      isError: false,
      isSaved: false,
      readyToDelete: false,
    };

    this.delete = this.delete.bind(this);
    this.fetchDrivers = this.fetchDrivers.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.prepareDelete = this.prepareDelete.bind(this);
  }

  async fetchDrivers() {
    try {
      const url = `${baseUrl}/users/`;
      const response = await fetch(url, {
        credentials: "include",
      });

      const result = await response.json();
      const drivers = result.filter((x) => x.role === "DRIVER");

      this.setState({
        drivers,
      });
    } catch {
      this.setState({
        fetchDriverError: true,
      });
    }
  }

  prepareDelete() {
    this.setState((prevState) => ({
      readyToDelete: !prevState.readyToDelete,
    }));
  }

  async delete(e) {
    e.preventDefault();

    try {
      const id = this.props.match.params.id;

      const response = await fetch(`${baseUrl}/cars/${id}`, {
        method: "DELETE",
        credentials: "include",
      });

      if (response.status === 204) {
        this.setState({
          hasCustomer: true,
        });

        return;
      }

      this.setState({
        isDeleted: true,
      });

      this.props.history.push("/fahrzeuge/");
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchCar() {
    try {
      const id = this.props.match.params.id;

      const response = await fetch(`${baseUrl}/cars/${id}`, {
        credentials: "include",
      });
      const {
        name,
        manufacturer,
        license,
        size,
        driver,
      } = await response.json();

      this.setState({
        name,
        manufacturer,
        license,
        size,
        driver,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  async handleSubmit(e) {
    e.preventDefault();

    try {
      const state = this.state;

      const car = {
        name: state.name,
        manufacturer: state.manufacturer,
        license: state.license,
        size: state.size,
        driver: state.driver,
      };

      const id = this.props.match.params.id;
      const method = id ? "PUT" : "POST";

      const response = await fetch(`${baseUrl}/cars/${id}`, {
        method: method,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(car),
      });

      if (response.status === 204) {
        this.setState({
          driverIsEngaged: true,
        });

        return;
      }

      this.setState({
        isSaved: true,
      });

      this.props.history.push("/fahrzeuge");
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchDrivers();
    this.fetchCar();
  }

  render() {
    const siteContent = this.state.isError ? <FetchError /> : this.renderForm();

    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Fahrzeug bearbeiten</h1>
        </div>
        {this.renderDriverHasCarWarning()}
        {this.renderHasCustomerWarning()}
        {siteContent}
      </div>
    );
  }

  renderDriverHasCarWarning() {
    if (!this.state.driverIsEngaged) {
      return;
    }

    return (
      <div className="alert alert-danger" role="alert">
        Das ausgewählte Fahrerkonto ist bereits einem Fahrzeug zugeordnet.
      </div>
    );
  }

  renderForm() {
    const { name, manufacturer, license, size } = this.state;

    const readyToDelete = this.state.readyToDelete;
    const deleteButton = readyToDelete ? (
      <React.Fragment>
        <button className="btn btn-danger mr-2" onClick={this.delete}>
          Fahrzeug löschen
        </button>
        <button className="btn btn-success mr-2" onClick={this.prepareDelete}>
          Abbrechen
        </button>
      </React.Fragment>
    ) : (
      this.props.match.params.id && (
        <button className="btn btn-danger" onClick={this.prepareDelete}>
          Löschen
        </button>
      )
    );

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Bezeichnung</label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Name"
            value={name}
            onChange={this.handleChange}
            required={true}
          />
        </div>

        <div className="form-group">
          <label htmlFor="manufacturer">Hersteller</label>
          <input
            type="text"
            className="form-control"
            id="manufacturer"
            placeholder="Hersteller"
            value={manufacturer}
            onChange={this.handleChange}
            required={true}
          />
        </div>

        <div className="form-group">
          <label htmlFor="license">KFZ-Kennzeichen</label>
          <input
            type="text"
            className="form-control"
            id="license"
            placeholder="Kennzeichen"
            value={license}
            onChange={this.handleChange}
            required={true}
          />
        </div>

        <div className="form-group">
          <label htmlFor="size">Tankgröße</label>
          <div className="input-group">
            <input
              type="number"
              min={1}
              className="form-control"
              id="size"
              placeholder="Fassungsvermögen"
              value={size}
              onChange={this.handleChange}
              required={true}
            />
            <div className="input-group-append">
              <span className="input-group-text">m³</span>
            </div>
          </div>
        </div>

        {this.renderDriverSelect()}

        <div className="d-flex">
          {deleteButton}
          <div className="ml-auto">
            <button type="submit" className="btn btn-success">
              Änderungen speichern
            </button>
            <Link to="/fahrzeuge" className="btn btn-secondary ml-2">
              Zurück
            </Link>
          </div>
        </div>
      </form>
    );
  }

  renderDriverSelect() {
    const mappedDrivers = this.state.drivers.map((x, i) => (
      <option value={x._id}>{x.username}</option>
    ));

    return (
      <div className="form-group">
        <label>Fahrer</label>
        <select
          id="driver"
          className="form-control"
          onChange={this.handleChange}
          value={this.state.driver}
        >
          {mappedDrivers}
        </select>
      </div>
    );
  }

  renderHasCustomerWarning() {
    if (!this.state.hasCustomer) {
      return;
    }

    return (
      <div className="alert alert-danger" role="alert">
        Das Fahrzeug kann nicht gelöscht werden, so lange es einem Kunden
        zugeordnet ist.
      </div>
    );
  }
}

export default EditCar;
