import React from "react";

import {
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";

import { ocean } from "./ColorSchemes";

const renderCustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const getDataKeys = (stats) => {
  if (!stats) {
    return;
  }

  const dataKeys = [];
  const obj = stats[0];
  for (const key in obj) {
    if (key === "name") {
      continue;
    }

    dataKeys.push(key);
  }

  return dataKeys;
};

const getStrokeIndexes = (dataKeys) => {
  const colorIndexes = [];
  let index = 0;

  for (let i = 0; i < dataKeys.length; i++) {
    if (i === 0) {
      colorIndexes.push(index);
      index++;
      continue;
    }

    if (i % 5 === 0) {
      index = 0;
    }

    colorIndexes.push(index);
    index++;
  }

  return colorIndexes;
};

const MultiLineChartVisualization = ({ stats }) => {
  const dataKeys = getDataKeys(stats);
  const strokeIndexes = getStrokeIndexes(dataKeys);
  const colors = Object.values(ocean);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={stats}
        margin={{
          top: 5,
          right: 0,
          left: 5,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={renderCustomizedAxisTick} />
        <YAxis />
        <Tooltip />
        <Legend layout="horizontal" align="center" verticalAlign="top" />
        {dataKeys.map((x, i) => (
          <Line
            type="monotone"
            dataKey={x}
            stroke={colors[strokeIndexes[i]]}
            strokeWidth={2}
            activeDot={{ r: 8 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MultiLineChartVisualization;
