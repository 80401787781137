import React from "react";

import OptionalInputTag from "./OptionalInputTag";

const FaxInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="fax">Fax</label>
      <OptionalInputTag />
      <input
        type="text"
        id="fax"
        className="form-control"
        value={props.fax}
        onChange={props.handleChange}
      />
    </div>
  );
};

export default FaxInput;
