import React from "react";

import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";

import { ocean } from "./ColorSchemes";

const createStatsObject = (stats) => {
  if (!stats) {
    return;
  }

  return {
    name: stats.vehicle.license,
    erledigt: stats.done,
    gesamt: stats.total,
    offen: stats.open,
  };
};

const BarChartVisualization = ({ vehicleStats }) => {
  const data = vehicleStats.map((x) => createStatsObject(x));

  return (
    <ResponsiveContainer width="100%" height={275}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
              <Bar dataKey="offen" stackId="a" fill={ocean.lightest} maxBarSize={50} />
        <Bar dataKey="erledigt" stackId="a" fill={ocean.light} maxBarSize={50} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartVisualization;
