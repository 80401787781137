import React from "react";
import { Route, Switch } from "react-router-dom";

import Detail from "./Detail";
import List from "./list/Index";
import Preview from "./preview/Index";
import Receipt from "./Receipt";
import Report from "./Report";

class RideIndex extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/fahrten/auswertung" component={Report} />
        <Route exact path="/fahrten/vorschau" component={Preview} />
        <Route exact path="/fahrten/:id" component={Detail} />
        <Route exact path="/fahrten/:id/beleg" component={Receipt} />
        <Route component={List} />
      </Switch>
    );
  }
}

export default RideIndex;
