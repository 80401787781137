import React from "react";
import { Link } from "react-router-dom";

import FetchError from "../shared/FetchError";
import { baseUrl } from "../../utils/baseUrl";

class EditClient extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      street: "",
      zipcode: "",
      city: "",
      landline: "",
      fax: "",
      mobile: "",
      vatNo: "",

      licenseExpiryDate: "",
      maxUsers: "",

      isError: false,
      isSaved: false,
    };

    this.fetchClient = this.fetchClient.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  async fetchClient() {
    try {
      const url = `${baseUrl}/client/`;

      const response = await fetch(url, {
        credentials: "include",
      });

      const result = await response.json();

      if (result.length === 0) {
        return;
      }

      const client = result;

      this.setState({
        id: client._id,
        name: client.name,
        street: client.adress.street,
        city: client.adress.city,
        zipcode: client.adress.zipcode,
        landline: client.landline,
        fax: client.fax,
        mobile: client.mobile,
        licenseExpiryDate: client.licenseExpiryDate,
        maxUsers: client.maxUsers,
        updadetAt: client.updated_at,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    try {
      const state = this.state;
      const adress = {
        street: state.street,
        zipcode: state.zipcode,
        city: state.city,
      };

      const client = {
        name: state.name,
        adress: adress,
        landline: state.landline,
        fax: state.fax,
        mobile: state.mobile,
        vatNo: state.vatNo,
      };

      if (this.state.id) {
        client.id = this.state.id;
      }

      const url = `${baseUrl}/client/`;

      const method = state.id ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(client),
      });

      const result = await response.json();

      this.setState(
        {
          id: result._id,
          name: result.name,
          street: result.adress.street,
          city: result.adress.city,
          zipcode: result.adress.zipcode,
          landline: result.landline,
          fax: result.fax,
          updadetAt: result.updated_at,

          isSaved: true,
        },
        () => this.fetchClient()
      );
    } catch (error) {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchClient();
  }

  render() {
    const state = this.state;
    const formContent = state.isError ? (
      <FetchError />
    ) : (
      this.renderClientForm()
    );

    const lastUpdated = new Date(state.updadetAt).toLocaleDateString();

    return (
      <div className="container">
        <div className="pb-2 mt-4 border-bottom">
          <h2>Stammdaten</h2>
        </div>
        <div className="d-flex">
          <p className="small mt-1 mb-3 text-muted">
            Letzte Aktualisierung: {lastUpdated}
          </p>
        </div>
        {formContent}
        {this.renderSavedWarning()}
      </div>
    );
  }

  renderSavedWarning() {
    if (!this.state.isSaved) {
      return;
    }

    return (
      <div className="alert alert-success mt-3" role="alert">
        Änderungen gespeichert.
      </div>
    );
  }

  renderClientForm() {
    const state = this.state;

    const licenseExpiryDate = new Date(
      state.licenseExpiryDate
    ).toLocaleDateString();

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name / Firmenname</label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Name"
            value={state.name}
            onChange={this.handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="street">Straße & Hausnummer</label>
          <input
            type="text"
            className="form-control"
            id="street"
            placeholder="Strasse"
            value={state.street}
            onChange={this.handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="zipcode">Postleitzahl</label>
          <input
            type="text"
            pattern="[0-9]{5}"
            className="form-control"
            id="zipcode"
            placeholder="PLZ"
            value={state.zipcode}
            onChange={this.handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="city">Ort</label>
          <input
            type="text"
            className="form-control"
            id="city"
            placeholder="Ort"
            value={state.city}
            onChange={this.handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="landline">Festnetz</label>
          <input
            type="text"
            // pattern="[0-9]{1,50}"
            className="form-control"
            id="landline"
            value={state.landline}
            onChange={this.handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="fax">Fax</label>
          <input
            type="text"
            // pattern="[0-9]{1,50}"
            className="form-control"
            id="fax"
            value={state.fax}
            onChange={this.handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="licenseExpiryDate">Lizenz gültig bis</label>
          <input
            type="text"
            className="form-control"
            id="licenseExpiryDate"
            value={licenseExpiryDate}
            disabled={true}
          />
        </div>

        <div className="form-group">
          <label htmlFor="maxUsers">Maximale Anzahl Benutzerkonten</label>
          <input
            type="text"
            className="form-control"
            id="maxUsers"
            value={state.maxUsers}
            disabled={true}
          />
        </div>

        <button type="submit" className="btn btn-success mr-2">
          Änderungen speichern
        </button>
        <Link to="/" className="btn btn-secondary mr-2">
          Zurück
        </Link>
      </form>
    );
  }
}

export default EditClient;
