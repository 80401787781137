import React from "react";

const TavAreaInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="tavArea">TAV Entsorgungsgebiet</label>
      <input
        type="string"
        className="form-control"
        id="tavArea"
        value={props.tavArea}
        onChange={props.handleChange}
        required={true}
      />
    </div>
  );
};

export default TavAreaInput;
