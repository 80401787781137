import React from "react";
import AsyncSelect from "react-select/async";

import DatePicker from "../shared/DatePicker";
import { baseUrl } from "../../utils/baseUrl";

class CreateRide extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      customerSelected: "",
      customerIndex: 0,
      carSelected: "",
      carIndex: 0,
      cars: [],
      customers: [],
      lastCreated: {},
      pickUpDate: new Date(),
      isError: false,
      isCreated: false,
      query: "",
      rideNote: "",
    };

    this.deactivateCreated = this.deactivateCreated.bind(this);
    this.fetchCars = this.fetchCars.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCarChange = this.handleCarChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleCreated = this.handleCreated.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
  }

  deactivateCreated() {
    this.setState({
      isCreated: false,
    });
  }

  async fetchCars() {
    try {
      const url = `${baseUrl}/cars`;
      const respone = await fetch(url, {
        credentials: "include",
      });
      const cars = await respone.json();

      const session = localStorage.getItem("user");
      const user = JSON.parse(session);

      let carSelected = cars.find((x) => x.driver._id === user._id);

      if (!carSelected) {
        carSelected = cars[0];
      }

      this.setState({
        cars,
        carSelected,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  filterOptions() {
    return this.state.customers.map((x, i) => ({
      value: x,
      label: `${x.adress.city}, ${x.adress.street}, ${
        x.company ? x.company : x.name
      }`,
    }));
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  handleClose(e) {
    e.preventDefault();

    this.setState({ customerSelected: "" });
    this.props.toggleCreateRide();
  }

  handleCreated() {
    this.setState(
      (prevState) => (
        {
          isCreated: !prevState.isCreated,
        },
        setTimeout(this.deactivateCreated, 12000)
      )
    );
  }

  handleCarChange(e) {
    e.preventDefault();

    this.setState({
      carSelected: this.state.cars[e.target.value],
      carIndex: e.target.value,
    });
  }

  handleCustomerChange(selected) {
    const selectedOption = {
      value: selected.value,
      label: selected.label,
    };

    this.setState({
      customerSelected: selected.value,
      selectedOption,
    });
  }

  handleDateChange = (pickUpDate) => {
    this.setState({
      pickUpDate,
    });
  };

  async handleSubmit(e) {
    e.preventDefault();

    const car = this.state.carSelected;
    const customer = this.state.customerSelected;
    const pickUpDate = this.state.pickUpDate;
    const rideNote = this.state.rideNote;

    const ride = {
      car,
      customer,
      pickUpDate,
      rideNote,
    };

    try {
      const url = `${baseUrl}/rides`;
      await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(ride),
      });

      this.setState(
        {
          isCreated: true,
          lastCreated: ride,
        },
        () => this.handleCreated()
      );

      this.props.onCreated();
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  handleInputChange = (newValue) => {
    // const inputValue = newValue.replace(/\W/g, ""); Uncomment to remove special chars
    const inputValue = newValue;
    this.setState({
      inputValue,
    });

    return inputValue;
  };

  async loadOptions() {
    try {
      const query = this.state.inputValue;

      const url = `${baseUrl}/customers/list/?value=${query}`;
      const response = await fetch(url, {
        credentials: "include",
      });
      const result = await response.json();

      this.setState({
        customers: result,
      });

      return this.filterOptions(result);
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchCars();
  }

  render() {
    const state = this.state;
    const renderCreatedAlarm = state.isCreated ? (
      <div className="alert alert-success mt-3" role="alert">
        Fahrt hinzugefügt für <b>{state.lastCreated.customer.name}</b> am{" "}
        <b>{state.lastCreated.pickUpDate.toLocaleDateString()}</b> mit Fahrzeug{" "}
        <b>{state.lastCreated.car.license}</b>.
      </div>
    ) : (
      ""
    );

    const isDisabled = this.state.customerSelected.length === 0;

    return (
      <React.Fragment>
        {this.renderCustomerSelect()}
        {this.renderCarSelect()}

        <div className="form-group col-md p-0">
          <label>Entsorgungsdatum</label>
          <DatePicker
            date={state.pickUpDate}
            onChange={this.handleDateChange}
          />
        </div>

        {this.renderAddRideNote()}

        <button
          className="btn btn-success mr-1"
          onClick={this.handleSubmit}
          disabled={isDisabled}
        >
          Fahrt erstellen
        </button>
        <button className="btn btn-primary mr-1" onClick={this.handleClose}>
          Schließen
        </button>
        {renderCreatedAlarm}
      </React.Fragment>
    );
  }

  renderAddRideNote() {
    return (
      <div className="form-group col-md p-0">
        <label>Abfuhrnotiz hinzufügen</label>
        <textarea
          id="rideNote"
          className="form-control mb-3"
          rows="4"
          onChange={this.handleChange}
          value={this.state.rideNote}
        />
      </div>
    );
  }

  renderCustomerSelect() {
    return (
      <div className="form-group">
        <label htmlFor="customerSelect">Kunde</label>
        <span id="customerSelect">
          <AsyncSelect
            loadOptions={this.loadOptions}
            onChange={this.handleCustomerChange}
            onInputChange={this.handleInputChange}
            placeholder={"Ort, Straße oder Name eingeben um zu suchen"}
          />
        </span>
      </div>
    );
  }

  renderCarSelect() {
    const session = localStorage.getItem("user");
    const user = JSON.parse(session);

    if (user.role === "DRIVER") {
      return;
    }

    if (!this.state.cars) {
      return;
    }

    const carOptions = this.state.cars.map((x, i) => (
      <option key={i} value={i}>
        {x.license}
      </option>
    ));

    return (
      <div className="form-group">
        <label htmlFor="car">Fahrzeug</label>
        <select
          id="car"
          className="form-control"
          onChange={this.handleCarChange}
          value={this.state.carIndex}
        >
          {carOptions}
        </select>
      </div>
    );
  }
}

export default CreateRide;
