import "./navigation.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

import Logout from "./Logout";

class Navigation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      logoutError: false,
    };
  }

  isAuthentificated() {
    const session = localStorage.getItem("user");
    const user = JSON.parse(session);

    if (!user) {
      return false;
    }

    if (user.name) {
      return true;
    }

    return true;
  }

  getUserRole() {
    const user = localStorage.getItem("user");
    if (!user) {
      return;
    }

    const userObject = JSON.parse(user);

    return userObject.role;
  }

  renderAuthentificated() {
    const session = localStorage.getItem("user");
    if (!session) {
      return;
    }

    const user = JSON.parse(session);
    if (!user.username) {
      return;
    }

    const userRole = this.getUserRole();
    const isDriver = userRole === "DRIVER";
    const isRoot = userRole === "ROOT";

    return session ? (
      <div className="collapse navbar-collapse" id="navbar">
        <ul className="navbar-nav mr-auto">
          {!isDriver && (
            <React.Fragment>
              <Link to="/dashboard" className="nav-item nav-link" title="Kunden">
                Dashboard
              </Link>
              <Link to="/" className="nav-item nav-link" title="Kunden">
                Kunden
              </Link>
              <Link
                to="/benutzer"
                className="nav-item nav-link"
                title="Benutzer"
              >
                Benutzer
              </Link>
              <Link
                to="/fahrzeuge"
                className="nav-item nav-link"
                title="Fahrzeuge"
              >
                Fahrzeuge
              </Link>
              <Link
                to="/stammdaten"
                className="nav-item nav-link"
                title="Stammdaten"
              >
                Stammdaten
              </Link>
              <Link
                to="/fahrten/auswertung"
                className="nav-item nav-link"
                title="Auswertung"
              >
                Auswertung
              </Link>
            </React.Fragment>
          )}
          <Link to="/fahrten" className="nav-item nav-link" title="Fahrten">
            Fahrten
          </Link>
          <Link
            to="/fahrten/vorschau"
            className="nav-item nav-link"
            title="Vorschau"
          >
            Vorschau
          </Link>
          {isRoot && (
            <Link to="/root" className="nav-item nav-link" title="Auswertung">
              Root
            </Link>
          )}
        </ul>
        <form className="form-inline mt-2">
          <Logout />
        </form>
      </div>
    ) : (
      ""
    );
  }

  componentDidMount() {
    $(".nav-item").on("click", function () {
      $(".navbar-collapse").collapse("hide");
    });

    $("#content").on("click", function () {
      $(".navbar-collapse").collapse("hide");
    });
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <a className="navbar-brand" href="/">
            <FontAwesomeIcon fixedWidth={true} icon={faHome} />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {this.renderAuthentificated()}
        </div>
      </nav>
    );
  }
}

export default Navigation;
