import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import Datepicker from "react-datepicker";

const DatePicker = (props) => {
  const date = () => {
    return new Date(props.date);
  };

  const minDate = () => {
    if (!props.minDate) {
      return;
    }

    return props.minDate;
  };

  return (
    <Datepicker
      className="form-control"
      dateFormat="dd/MM/yyyy"
      minDate={minDate()}
      selected={date()}
      onChange={props.onChange}
      disabled={props.disabled}
    />
  );
};

export default DatePicker;
