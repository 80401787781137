import React from "react";

const IntervalInput = (props) => {
  const smallContent = props.id
    ? "Um regelmäßige Abfuhren ab Datum heute zu deaktivieren, 0 eingeben. Größer 0 für Aktivieren eingeben."
    : "Für einmalige Abfuhr Intervall 0 eingeben.";

  return (
    <div className="form-group">
      <label htmlFor="interval">Intervall in Wochen</label>
      <input
        type="number"
        min={0}
        step={1}
        className="form-control"
        id="interval"
        value={props.interval}
        onChange={props.handleChange}
        required={true}
      />
      <small className="text-muted">{smallContent}</small>
    </div>
  );
};

export default IntervalInput;
