import React from "react";

import DatePicker from "../../shared/DatePicker";

import "./item.scss";

const RidePreviewItem = (props) => {
  const address = props.address;
  const date = new Date(props.date);
  const minDate = new Date();
  const name = props.name;

  const handleChange = (date) => {
    props.handleSubmit(props.id, date);
  };

  return (
    <React.Fragment>
      <li className="list-group-item item d-flex">
        <span className="mr-3">{`${address.city}, ${address.street}, ${name}`}</span>
        <span className="ml-auto date-picker">
          <DatePicker date={date} minDate={minDate} onChange={handleChange} />
        </span>
      </li>
    </React.Fragment>
  );
};

export default RidePreviewItem;
