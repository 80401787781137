import React from "react";

const DaysToPreviewSelect = (props) => {
  const days = props.days;

  const handleChange = (e) => {
    e.preventDefault();

    const days = parseInt(e.target.value);
    props.handleChange(days);
  };

  return (
    <div className="d-flex">
      <span className="mt-auto mr-2 text-muted">Tage:</span>
      <select
        className="form-control"
        id="preview-select"
        value={days}
        onChange={handleChange}
      >
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
      </select>
    </div>
  );
};

export default DaysToPreviewSelect;
