import React from "react";

import DatePicker from "../../../shared/DatePicker";

const StartDateInput = (props) => {
  const id = props.id;
  const disabled = id === undefined ? false : true;
  const startDate = props.startDate;

  return (
    <div className="form-group">
      <label htmlFor="startDate">Startdatum</label>
      <DatePicker
        date={startDate}
        minDate={new Date()}
        onChange={props.handleChange}
        disabled={disabled}
      />
    </div>
  );
};

export default StartDateInput;
