import "./Receipt.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote, faInfo } from "@fortawesome/free-solid-svg-icons";

import React from "react";

import DatePicker from "../shared/DatePicker";
import {
  CarsService,
  ClientService,
  RidesService,
  SynchService,
} from "../../services/DBService";

function getStateBadge(state) {
  switch (state) {
    case 0:
      return <span class="badge badge-primary">Offen</span>;
    case 1:
      return <span class="badge badge-success">Erledigt</span>;
    case 2:
      return <span class="badge badge-danger">Abgelehnt</span>;

    default:
      return "";
  }
}

function getDateField(state, date) {
  switch (state) {
    case 0:
      return <span>Geplant: {date.toLocaleDateString()}</span>;
    case 1:
      return <span>Entleert: {date.toLocaleString("de-DE")}</span>;
    case 2:
      return <span>Abgelehnt: {date.toLocaleString("de-DE")}</span>;

    default:
      return "";
  }
}

class RideReceipt extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      hoseLength: 0,
      id: this.props.match.params.id,
      quantity: 0,

      giveReasonToDecline: false,
      giveReasonToDelegate: false,

      isError: false,
      isUpdated: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.fetchClient = this.fetchClient.bind(this);
    this.fetchRide = this.fetchRide.bind(this);
    this.finishRide = this.finishRide.bind(this);
    this.rejectRide = this.rejectRide.bind(this);
    this.resetRide = this.resetRide.bind(this);
    this.updateRide = this.updateRide.bind(this);
  }

  handleCheckboxChange(e) {
    let value = {};
    value[e.target.id] = e.target.checked;

    this.setState(value);
  }

  handleCollapse(e) {
    e.preventDefault();

    const collapse = e.target.id;
    let collapseState = this.state[collapse];

    this.setState({
      [collapse]: !collapseState,
    });
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  handleDate = (date) => {
    this.setState({
      newDate: date,
    });
  };

  async fetchCars() {
    try {
      const cars = await CarsService.getAll();

      this.setState({
        cars,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchClient() {
    try {
      const clientDoc = await ClientService.getAll();
      const client = clientDoc[0];

      this.setState({
        client,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchRide() {
    const id = this.props.match.params.id;

    try {
      const ride = await RidesService.get(id);

      const {
        consumer,
        state,
        date,
        quantity,
        notes,
        reasonToDecline,
        reasonToDelegate,
        rideNote,
        car,
      } = ride;

      const hoseLength = consumer.hoseLength ? consumer.hoseLength : 0;

      this.setState({
        car,
        consumer,
        date,
        hoseLength,
        notes,
        quantity,
        rideNote,
        reasonToDecline,
        reasonToDelegate,
        state,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async updateRide() {
    const id = this.props.match.params.id;
    const state = this.state;

    const consumer = state.consumer;
    consumer.hoseLength = state.hoseLength;

    const ride = {
      _id: id,
      car: state.car,
      consumer: consumer,
      hoseLength: state.hoseLength,
      date: state.date,
      notes: state.notes,
      quantity: state.quantity,
      state: state.state,
    };

    if (state.reasonToDecline) {
      ride.reasonToDecline = state.reasonToDecline;
    }

    if (state.reasonToDelegate) {
      ride.reasonToDelegate = state.reasonToDelegate;
    }

    if (this.state.newDate) {
      ride.date = this.state.newDate;
    }

    if (this.state.newCar) {
      ride.car = this.state.newCar;
    }

    try {
      await RidesService.put(ride);
      await SynchService.put(ride);
      await ClientService.update(consumer._id, consumer);

      this.setState({
        isUpdated: true,
      });

      this.fetchRide();
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  finishRide(e) {
    e.preventDefault();

    this.setState(
      {
        date: new Date(),
        state: 1,
      },
      () => {
        this.updateRide(e);
      }
    );
  }

  rejectRide(e) {
    e.preventDefault();

    this.setState(
      {
        date: new Date(),
        state: 2,
      },
      () => {
        this.updateRide(e);
      }
    );
  }

  async resetRide(e) {
    e.preventDefault();

    this.setState({
      date: new Date(),
      quantity: 0,
      state: 0,
    });

    const id = this.state.id;
    const ride = {
      _id: id,
      car: this.state.car,
      consumer: this.state.consumer,
      hoseLength: this.state.hoseLength,
      date: this.state.date,
      notes: this.state.notes,
      quantity: this.state.quantity,
      state: 0,
    };

    await RidesService.update(id, ride);
    await SynchService.delete(id);
  }

  async componentDidMount() {
    this.fetchRide();
    this.fetchClient();
    this.fetchCars();
  }

  render() {
    const state = this.state;
    const consumer = state.consumer;

    if (!consumer) {
      return <div className="container">No Consumer</div>;
    }

    const resetButton =
      state.state === 1 || state.state === 2 ? this.renderResetStatus() : "";

    return (
      <div className="container">
        <div className="pc-2 mt-4 mb-4 border-bottom d-flex">
          <h1>Abfuhrbeleg</h1>
          <span className="ml-auto mt-2">
            <button
              className="btn btn-secondary"
              onClick={() => this.props.history.goBack()}
            >
              Zurück
            </button>
          </span>
        </div>
        {this.renderReceipt()}
        {this.renderUpdateWarning()}
        <hr />
        {this.renderInteraction()}
        {resetButton}
      </div>
    );
  }

  renderReceipt() {
    const state = this.state;
    const consumer = state.consumer;
    const client = state.client;

    if (!client || !consumer) {
      return;
    }

    const dateObject = new Date(state.date);
    const dateField = getDateField(state.state, dateObject);

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-6">
            <pre id="pre_print" className="d-one">
              <b>Entleerungsnachweis</b>{" "}
              <span className="ml-auto no-print">
                {getStateBadge(state.state)}
              </span>
              <br />
              {dateField} <br />
              <br />
              <b>Fuhrunternehmen</b> <br />
              {client.name} <br />
              {client.adress.street} <br />
              {client.adress.zipcode} {client.adress.city}{" "}
              {client.adress.district} <br />
              Tel.{/* /Fax  */} {client.landline} {/* / {client.fax} */} <br />
              <br />
              <b>Kunde</b> <br />
              Kd-Nr.{" "}
              {consumer.referenceNumber ? consumer.referenceNumber : "n/a"}{" "}
              <br />
              {consumer.name} <br />
              {consumer.adress.street} <br />
              {consumer.adress.zipcode} {consumer.adress.city} <br />
              {consumer.adress.district ? "OT" + consumer.adress.district : ""}
              <br />
              ---------------------------
              <br />
              {/* <b>Schlauchlänge:</b> {state.hoseLength} m<br /> */}
              <b>Abwassermenge:</b> {state.quantity} m³
              <br />
              ---------------------------
              <br />
              <b>Unterschrift Fahrer:</b> <br />
              <br />
              <br />
              <b>Unterschrift Kunde:</b> <br />
              <br />
              <br />
              <br />
            </pre>
          </div>

          <div className="col-sm-6">
            <span className="no-print">
              <b>Stammnotizen</b>{" "}
              <FontAwesomeIcon fixedWidth={true} icon={faStickyNote} />
              <br />
              {consumer.notes ? consumer.notes : "Keine Notizen vorhanden"}
            </span>
            <br />
            {this.renderRideNote()}
            {this.renderReasonToDecline()}
            {this.renderReasonToDelegate()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderRideNote() {
    let noteContent = "Keine Hinweise vorhanden";
    if (this.state.rideNote) {
      noteContent = this.state.rideNote;
    }

    return (
      <span className="no-print">
        <b>Abfuhrhinweis</b> <FontAwesomeIcon fixedWidth={true} icon={faInfo} />
        <br />
        <span>{noteContent}</span>
      </span>
    );
  }

  renderReasonToDecline() {
    if (this.state.state === 2 && this.state.reasonToDecline) {
      return (
        <div className="no-print">
          <b>Grund für Ablehnung:</b> <br />
          {this.state.reasonToDecline}
        </div>
      );
    }

    return;
  }

  renderReasonToDelegate() {
    if (this.state.reasonToDelegate) {
      return (
        <div className="no-print">
          <b>Grund für Übergabe:</b> <br />
          {this.state.reasonToDelegate}
        </div>
      );
    }

    return;
  }

  renderInteraction() {
    if (this.state.state === 1) {
      return this.renderPrintReceipt();
    }

    return this.renderAccordion();
  }

  renderAccordion() {
    return (
      <div id="accordion">
        <div class="ride-collapse mt-3 mb-3">
          <h5 class="mb-3" id="headingCollapseDone">
            {this.renderDoneButton()}
          </h5>
          <div
            id="collapseDone"
            class="collapse"
            aria-labelledby="headingCollapseDone"
            data-parent="#accordion"
          >
            {this.renderDoneForm()}
          </div>
        </div>

        <div class="ride-collapse mt-3 mb-3">
          <h5 class="mb-3" id="headingCollapseDecline">
            {this.renderDeclineButton()}
          </h5>
          <div
            id="collapseDecline"
            class="collapse"
            aria-labelledby="headingCollapseDecline"
            data-parent="#accordion"
          >
            {this.renderDeclineForm()}
          </div>
        </div>

        <div class="ride-collapse mt-3 mb-3">
          <h5 class="mb-3" id="headingCollapseChangeDate">
            {this.renderChangeDateButton()}
          </h5>
          <div
            id="collapseChangeDate"
            class="collapse"
            aria-labelledby="headingCollapseChangeDate"
            data-parent="#accordion"
          >
            {this.renderChangeDateForm()}
          </div>
        </div>

        <div class="ride-collapse mt-3 mb-3">
          <h5 class="mb-3" id="headingCollapseChangeCar">
            {this.renderChangeCarButton()}
          </h5>
          <div
            id="collapseChangeCar"
            class="collapse"
            aria-labelledby="headingCollapseChangeCar"
            data-parent="#accordion"
          >
            {this.renderChangeCarForm()}
          </div>
        </div>
      </div>
    );
  }

  renderDoneButton() {
    const isDisabled = this.state.state === 1 || this.state.state === 2;

    return (
      <button
        className="btn btn-success btn-lg btn-block"
        data-toggle="collapse"
        data-target="#collapseDone"
        aria-expanded="true"
        aria-controls="collapseDone"
        disabled={isDisabled}
      >
        Erledigen
      </button>
    );
  }

  renderDeclineButton() {
    const isDisabled = this.state.state === 1 || this.state.state === 2;

    return (
      <button
        class="btn btn-danger btn-lg btn-block"
        data-toggle="collapse"
        data-target="#collapseDecline"
        aria-expanded="true"
        aria-controls="collapseDecline"
        disabled={isDisabled}
      >
        Ablehnen
      </button>
    );
  }

  renderChangeDateButton() {
    const isDisabled = this.state.state === 1 || this.state.state === 2;

    return (
      <button
        class="btn btn-primary btn-lg btn-block"
        data-toggle="collapse"
        data-target="#collapseChangeDate"
        aria-expanded="true"
        aria-controls="collapseChangeDate"
        disabled={isDisabled}
      >
        Datum ändern
      </button>
    );
  }

  renderChangeCarButton() {
    const isDisabled =
      !navigator.onLine || this.state.state === 1 || this.state.state === 2;

    return (
      <button
        class="btn btn-primary btn-lg btn-block"
        data-toggle="collapse"
        data-target="#collapseChangeCar"
        aria-expanded="true"
        aria-controls="collapseChangeCar"
        disabled={isDisabled}
      >
        Fahrzeug ändern
      </button>
    );
  }

  printToBlueTooth() {
    const S = "#Intent;scheme=rawbt;";
    const P = "package=ru.a402d.rawbtprinter;end;";

    const printMessage = document.getElementById("pre_print").innerText;
    const textEncoded = encodeURI(printMessage);

    window.location.href = "intent:" + textEncoded + S + P;
  }

  renderPrintReceipt() {
    return (
      <button
        className="btn btn-success btn-lg btn-block mt-3 mb-3"
        onClick={this.printToBlueTooth}
      >
        Abfuhrbeleg drucken
      </button>
    );
  }

  renderDoneForm() {
    if (!this.state.consumer) {
      return "Kunde nicht gefunden";
    }

    const valueQuantity = this.state.quantity === 0 ? " " : this.state.quantity;
    const valueHoseLength =
      this.state.hoseLength === 0 ? " " : this.state.hoseLength;

    return (
      <form onSubmit={this.finishRide}>
        <div className="form-group">
          <label htmlFor="quantity">Menge in m³</label>
          <input
            className="form-control"
            id="quantity"
            type="number"
            min={0.5}
            step={0.5}
            max={this.state.consumer.pitSize}
            value={valueQuantity}
            onChange={this.handleChange}
            required={true}
          />
        </div>
        <div className="form-group">
          <label htmlFor="quantity">Schlauchlänge in m</label>

          <input
            className="form-control"
            id="hoseLength"
            type="number"
            min={0.5}
            step={0.5}
            value={valueHoseLength}
            onChange={this.handleChange}
            required={true}
          />
          <small className="text-muted">
            Basierend auf letzter erfasster Schlauchlänge. Wird nicht gedruckt.
          </small>
        </div>
        <button type="submit" className="btn btn-success mb-1">
          Bestätigen
        </button>
      </form>
    );
  }

  renderDeclineForm() {
    const textArea = this.state.giveReasonToDecline ? (
      <div className="form-group">
        <label for="reasonToDecline">Grund für Ablehnung</label>
        <textarea
          className="form-control"
          id="reasonToDecline"
          rows="2"
          onChange={this.handleChange}
        />
      </div>
    ) : (
      ""
    );

    return (
      <form className="form" onSubmit={this.rejectRide}>
        <div className="form-group form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="giveReasonToDecline"
            checked={this.state.giveReasonToDecline}
            onChange={this.handleCheckboxChange}
          />
          <label className="form-check-label" htmlFor="giveReasonToDecline">
            Grund angeben
          </label>
        </div>

        {textArea}

        <button
          type="submit"
          className="btn btn-danger mb-1"
          data-toggle="collapse"
          data-target="#collapseDecline"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          Bestätigen
        </button>
      </form>
    );
  }

  renderChangeCarForm() {
    if (!this.state.cars) {
      return;
    }

    const cars = this.state.cars;

    const newCar = this.state.newCar;
    let car = this.state.car;
    if (newCar) {
      car = cars.find((x) => x._id === this.state.newCar);
    }

    const options = cars.map((x, i) => (
      <option key={i} value={x._id}>
        {x.license}
      </option>
    ));

    const confirmDisabled = this.state.newCar ? false : true;

    const textArea = this.state.giveReasonToDelegate ? (
      <div className="form-group">
        <label for="reasonToDelegate">Grund für Übergabe</label>
        <textarea
          className="form-control"
          id="reasonToDelegate"
          rows="2"
          onChange={this.handleChange}
        />
      </div>
    ) : (
      ""
    );

    return (
      <form>
        <div className="form-group">
          <label htmlFor="newCar">Fahrzeug</label>
          <select
            id="newCar"
            className="form-control"
            onChange={this.handleChange}
            value={car._id}
          >
            {options}
          </select>
        </div>

        <div className="form-group form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="giveReasonToDelegate"
            checked={this.state.giveReasonToDelegate}
            onChange={this.handleCheckboxChange}
          />
          <label className="form-check-label" htmlFor="changePassword">
            Grund angeben
          </label>
        </div>

        {textArea}

        <button
          className="btn btn-success mb-1"
          data-toggle="collapse"
          data-target="#collapseChangeCar"
          aria-expanded="true"
          aria-controls="collapseOne"
          onClick={this.updateRide}
          disabled={confirmDisabled}
        >
          Bestätigen
        </button>
      </form>
    );
  }

  renderChangeDateForm() {
    const date = this.state.newDate ? this.state.newDate : this.state.date;

    return (
      <React.Fragment>
        <div className="form-group">
          <DatePicker date={date} onChange={this.handleDate} />
        </div>
        <button
          className="btn btn-success mb-1"
          data-toggle="collapse"
          data-target="#collapseChangeDate"
          aria-expanded="true"
          aria-controls="collapseOne"
          onClick={this.updateRide}
        >
          Bestätigen
        </button>
      </React.Fragment>
    );
  }

  renderResetStatus() {
    return (
      <button
        className="btn btn-warning btn-lg btn-block mt-3 mb-3"
        onClick={this.resetRide}
      >
        Status zurücksetzen
      </button>
    );
  }

  renderUpdateWarning() {
    if (!this.state.isUpdated) {
      return;
    }

    return (
      <div class="alert alert-success mt-3" role="alert">
        Entsorgungsfahrt wurde aktualisiert.
      </div>
    );
  }
}

export default RideReceipt;
