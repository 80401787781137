import { Link } from "react-router-dom";
import React from "react";

import FetchError from "../shared/FetchError";
import { baseUrl } from "../../utils/baseUrl";

class CreateCar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      driver: "",
      drivers: [],
      name: "",
      manufacturer: "",
      license: "",
      size: "",

      driverIsEngaged: false,

      fetchDriverError: false,
      isError: false,
      isSaved: false,
    };

    this.fetchDrivers = this.fetchDrivers.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async fetchDrivers() {
    try {
      const url = `${baseUrl}/users`;
      const response = await fetch(url, {
        credentials: "include",
      });

      const result = await response.json();
      const drivers = result.filter((x) => x.role === "DRIVER");

      this.setState({
        driver: drivers[0]._id,
        drivers,
      });
    } catch {
      this.setState({
        fetchDriverError: true,
      });
    }
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  async handleSubmit(e) {
    e.preventDefault();
    try {
      const state = this.state;

      const car = {
        name: state.name,
        manufacturer: state.manufacturer,
        license: state.license,
        size: state.size,
        driver: state.driver,
      };

      const response = await fetch(`${baseUrl}/cars/`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(car),
      });

      if (response.status === 204) {
        this.setState({
          driverIsEngaged: true,
        });

        return;
      }

      this.setState({
        isSaved: true,
      });

      this.props.history.push("/fahrzeuge");
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchDrivers();
  }

  render() {
    const siteContent = this.state.isError ? <FetchError /> : this.renderForm();

    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Neues Fahrzeug anlegen</h1>
        </div>
        {this.renderDriverHasCarWarning()}
        {siteContent}
      </div>
    );
  }

  renderDriverHasCarWarning() {
    if (!this.state.driverIsEngaged) {
      return;
    }

    return (
      <div className="alert alert-danger" role="alert">
        Das ausgewählte Fahrerkonto ist bereits einem Fahrzeug zugeordnet.
      </div>
    );
  }

  renderForm() {
    const { name, manufacturer, license, size } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label for="name">Bezeichnung</label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Name"
            value={name}
            onChange={this.handleChange}
            required={true}
          />
        </div>

        <div className="form-group">
          <label for="manufacturer">Hersteller</label>
          <input
            type="text"
            className="form-control"
            id="manufacturer"
            placeholder="Hersteller"
            value={manufacturer}
            onChange={this.handleChange}
            required={true}
          />
        </div>

        <div className="form-group">
          <label for="license">KFZ-Kennzeichen</label>
          <input
            type="text"
            className="form-control"
            id="license"
            placeholder="Kennzeichen"
            value={license}
            onChange={this.handleChange}
            required={true}
          />
        </div>

        <div className="form-group">
          <label for="size">Tankgröße</label>
          <div className="input-group">
            <input
              type="number"
              min={1}
              className="form-control"
              id="size"
              placeholder="Fassungsvermögen"
              value={size}
              onChange={this.handleChange}
              required={true}
            />
            <div className="input-group-append">
              <span className="input-group-text">m³</span>
            </div>
          </div>
        </div>

        {this.renderDriverSelect()}

        <div className="d-flex">
          <div className="ml-auto">
            <button type="submit" className="btn btn-success">
              Fahrzeugdaten speichern
            </button>
            <Link to="/fahrzeuge" className="btn btn-secondary ml-2">
              Zurück
            </Link>
          </div>
        </div>
      </form>
    );
  }

  renderDriverSelect() {
    let drivers = this.state.drivers;

    const mappedDrivers = drivers.map((x, i) => (
      <option value={x._id}>{x.username}</option>
    ));

    return (
      <div className="form-group">
        <label htmlFor="driver">Fahrer</label>
        <select
          id="driver"
          className="form-control"
          onChange={this.handleChange}
          value={this.state.driver}
        >
          {mappedDrivers}
        </select>
      </div>
    );
  }
}

export default CreateCar;
