import React from "react";
import { Switch, Route } from "react-router-dom";

import EditClient from "./Edit";

class ClientIndex extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route component={EditClient} />
      </Switch>
    );
  }
}

export default ClientIndex;
