import React from "react";

import { baseUrl } from "../utils/baseUrl";

class Login extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      responseMessage: "",
      isError: false,
      isLoggedIn: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  async handleSubmit(e) {
    e.preventDefault();

    try {
      const { username, password } = this.state;
      const user = {
        username,
        password,
      };
      console.log(baseUrl, username, password)
      const response = await fetch(`${baseUrl}/users/login`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
      
      const result = await response.json();

      if (response.status === 500 || response.status === 404) {
        this.setState({
          isError: true,
          responseMessage: result.message,
        });

        return;
      }

      if (result.status === 500) {
        this.setState({
          isError: true,
          responseMessage: result.message,
        });

        return;
      }

      localStorage.setItem("user", JSON.stringify(result));

      this.setState({
        isLoggedIn: true,
      });

      //window.location.reload();
      window.location.href = "/";
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="pb-2 mt-4 mb-4 border-bottom">
            <h2>Anmelden</h2>
          </div>

          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label for="username">Benutzername</label>
              <input
                type="text"
                className="form-control"
                id="username"
                onChange={this.handleChange}
                value={this.state.username}
                required={true}
              />
              <small id="usernameHelp" className="form-text text-muted">
                Bitte Benutzername eingeben.
              </small>
            </div>
            <div className="form-group">
              <label for="password">Passwort</label>
              <input
                type="password"
                className="form-control"
                id="password"
                onChange={this.handleChange}
                value={this.state.password}
                required={true}
              />
              <small id="passwordHelp" className="form-text text-muted">
                Bitte Passwort eingeben.
              </small>
            </div>

            <button type="submit" className="btn btn-primary">
              Anmelden
            </button>
          </form>
          {this.renderErrorMessage()}
        </div>
      </React.Fragment>
    );
  }

  renderErrorMessage() {
    if (!this.state.isError) {
      return;
    }

    return (
      <div className="alert alert-primary mt-3" role="alert">
        Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Anmeldedaten.{" "}
        {this.state.responseMessage}
      </div>
    );
  }
}

export default Login;
