import React from "react";
import { Link } from "react-router-dom";

import TaskPanel from "../shared/TaskPanel";
import FetchError from "../shared/FetchError";
import { baseUrl } from "../../utils/baseUrl";

class CarList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      cars: [],
      query: "",
    };

    this.fetchCars = this.fetchCars.bind(this);
  }

  async fetchCars() {
    try {
      const response = await fetch(`${baseUrl}/cars`, {
        credentials: "include",
      });

      const cars = await response.json();

      this.setState({
        cars,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchCars();
  }

  render() {
    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h2>Fahrzeuge</h2>
        </div>

        <TaskPanel>
          <Link to="/fahrzeuge/bearbeiten" className="btn btn-primary">
            Fahrzeug anlegen
          </Link>
        </TaskPanel>
        {this.state.isError ? <FetchError /> : this.renderList()}
      </div>
    );
  }

  renderList() {
    if (!this.state.cars) {
      return;
    }

    const sortedCars = this.state.cars.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    const cars = sortedCars.map((x, i) => (
      <tr>
        <td>{x.name}</td>
        <td>{x.manufacturer}</td>
        <td>{x.license}</td>
        <td>{x.size}</td>
        <td>{x.driver.username}</td>
        <td>
          <Link
            to={`/fahrzeuge/bearbeiten/${x._id}`}
            className="btn btn-primary"
          >
            Anzeigen
          </Link>
        </td>
      </tr>
    ));

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Fahrzeugname</th>
            <th>Hersteller</th>
            <th>Kennzeichen</th>
            <th>Tank m³</th>
            <th>Fahrer</th>
            <th>Auswahl</th>
          </tr>
        </thead>
        <tbody>{cars}</tbody>
      </table>
    );
  }
}

export default CarList;
