import React from "react";

import { Accordion } from "react-accessible-accordion";

import Item from "./Item";

import "./List.scss";

class RideList extends React.PureComponent {
  render() {
    const rides = this.props.rides;
    const user = this.props.user;

    const showDetails = user._id === user.selected || user.role !== "DRIVER";

    const items = rides.map((x) => <Item ride={x} showDetails={showDetails} />);

    return <Accordion allowZeroExpanded={true}>{items}</Accordion>;
  }
}

export default RideList;
