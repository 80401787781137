import React from "react";

const DisposalAmountInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor={props.id}>{props.label}</label>
      <div className="input-group">
        <input
          type="number"
          id={props.id}
          className="form-control"
          min={0.5}
          step={0.5}
          value={props.value}
          onChange={props.onChange}
          required={props.required}
        />
        <div className="input-group-append">
          <span className="input-group-text">m³</span>
        </div>
      </div>
    </div>
  );
};

export default DisposalAmountInput;
