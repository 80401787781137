import React from "react";
import { Route, Switch } from "react-router-dom";

import CreateCar from "./Create";
import EditCar from "./Edit";
import List from "./List";

const CarIndex = (props) => {
  return (
    <Switch>
      <Route exact path="/fahrzeuge/bearbeiten" component={CreateCar} />
      <Route exact path="/fahrzeuge/bearbeiten/:id" component={EditCar} />
      <Route component={List} />
    </Switch>
  );
};

export default CarIndex;
