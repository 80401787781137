import React from "react";

import Item from "./Item";
import PreviewSelect from "./PreviewSelect";
import { baseUrl } from "../../../utils/baseUrl";

const days = {
  0: "Sonntag",
  1: "Montag",
  2: "Dienstag",
  3: "Mittwoch",
  4: "Donnerstag",
  5: "Freitag",
  6: "Samstag",
};

function sortByPlaceAndStreet(ridesPerDay) {
  return ridesPerDay.sort((a, b) => {
    if (a.consumer.adress.city === b.consumer.adress.city) {
      return a.consumer.adress.street.localeCompare(b.consumer.adress.street);
    }
    return a.consumer.adress.city.localeCompare(b.consumer.adress.city);
  });
}

class Preview extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      daysToPreview: 3,
      startDate: this.setStartDate(),
      endDate: this.setEndDate(3),
      rides: [],
      user: this.getUser(),
    };

    this.fetchRides = this.fetchRides.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitDate = this.submitDate.bind(this);
  }

  getUser() {
    const session = localStorage.getItem("user");
    return JSON.parse(session);
  }

  getSortedRidesByDay(rides) {
    let dates = [];
    let ridesPerDay = [];
    let tmpArr = [];

    // Extract dates.
    for (const ride of rides) {
      dates.push(new Date(ride.date).getDate());
    }

    // Flatten dates.
    dates = [...new Set(dates)];

    // Filter rides by dates and sort.
    for (const date of dates) {
      tmpArr = rides.filter((x) => new Date(x.date).getDate() === date);
      tmpArr = sortByPlaceAndStreet(tmpArr);
      ridesPerDay.push(tmpArr);
    }

    return ridesPerDay;
  }

  setStartDate() {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    return startDate;
  }

  setEndDate(days) {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + days);
    return endDate;
  }

  handleChange(days) {
    this.setState(
      {
        daysToPreview: days,
        endDate: this.setEndDate(days),
      },
      () => this.fetchRides()
    );
  }

  async fetchRides() {
    const start = this.state.startDate;
    const end = this.state.endDate;

    const from = `${start.getFullYear()}-${
      start.getMonth() + 1
    }-${start.getDate()}`;
    const to = `${end.getUTCFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;
    const query = `from=${from}&to=${to}`;

    try {
      const url = `${baseUrl}/rides/all?${query}`;
      const response = await fetch(url, {
        credentials: "include",
      });
      const result = await response.json();

      const user = this.state.user._id;
      let rides = result.rides.filter((x) => x.car.driver === user);

      if (rides.length === 0) {
        rides = result.rides;
      }

      rides = rides.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });

      rides = this.getSortedRidesByDay(rides);

      this.setState({ rides });
    } catch {
      this.setState({ fetchError: true });
    }
  }

  async submitDate(id, date) {
    try {
      const url = `${baseUrl}/rides/date`;

      const response = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id, date: date }),
      });

      if (response.status === 200) {
        this.fetchRides();
      }
    } catch {
      this.setState({
        submitError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchRides();
  }

  render() {
    const start = this.state.startDate;
    const end = this.state.endDate;

    const from = start.toLocaleDateString("de-DE");
    const to = end.toLocaleDateString("de-DE");

    const days = this.state.daysToPreview;

    return (
      <div className="container">
        <div className="d-flex pb-2 mt-4 mb-4 border-bottom">
          <h2>Fahrtenvorschau Woche</h2>
        </div>
        <div className="d-flex">
          <h3>
            {from} bis {to}
          </h3>
          <span className="ml-auto">
            <PreviewSelect days={days} handleChange={this.handleChange} />
          </span>
        </div>
        <hr />
        {this.renderPreview()}
      </div>
    );
  }

  renderPreview() {
    const rides = this.state.rides;
    if (!rides || rides.length === 0) {
      return (
        <div className="alert alert-info text-center" role="alert">
          Für den gewählten Zeitraum sind keine Daten vorhanden.
        </div>
      );
    }

    let preview = [];
    for (const ride of rides) {
      let day = ride.map((ride, i) => {
        if (i === 0) {
          let date = new Date(ride.date);

          const daySeparator = this.renderDaySeparator(
            date.getDay(),
            ride.date
          );

          const firstItem = this.renderItem(ride, i);

          const firstDay = [daySeparator, firstItem];

          return [...firstDay];
        }

        return this.renderItem(ride, i);
      });

      preview = preview.concat(day);
    }

    return (
      <div className="pb-2 mt-4 border-bottom d-flex">
        <ul className="list-group w-100">{preview}</ul>
      </div>
    );
  }

  renderDaySeparator(day, rideDate) {
    const date = new Date(rideDate).toLocaleDateString("de-De");
    return (
      <li key={`day-separator-${day}`} className="list-group-item item active">
        {days[day]}, {date}
      </li>
    );
  }

  renderItem(ride, i) {
    const address = ride.consumer.adress;
    const date = ride.date;
    const id = ride._id;
    const name = ride.consumer.name;

    return (
      <React.Fragment key={`preview-item-${date}-${i}`}>
        <Item
          address={address}
          date={date}
          id={id}
          i={i}
          name={name}
          handleSubmit={this.submitDate}
        />
      </React.Fragment>
    );
  }
}

export default Preview;
