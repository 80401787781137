import React from "react";

const ZipInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="zipcode">Postleitzahl</label>
      <input
        type="text"
        pattern="[0-9]{5}"
        className="form-control"
        id="zipcode"
        value={props.zipcode}
        onChange={props.handleChange}
        required={true}
      />
    </div>
  );
};

export default ZipInput;
