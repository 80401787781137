import { Link } from "react-router-dom";
import React from "react";

import FetchError from "../shared/FetchError";
import { baseUrl } from "../../utils/baseUrl";

class EditUser extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      role: "",

      changePassword: false,
      hasCar: false,
      isError: false,
      isSaved: false,
      readyToDelete: false,
    };

    this.delete = this.delete.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.prepareDelete = this.prepareDelete.bind(this);
  }

  handleCheckboxChange(e) {
    let value = {};
    value[e.target.id] = e.target.checked;

    this.setState(value);
  }

  handleChange(e) {
    e.preventDefault();

    let value = {};
    value[e.target.id] = e.target.value;

    this.setState(value);
  }

  prepareDelete(e) {
    this.setState((prevState) => ({
      readyToDelete: !prevState.readyToDelete,
    }));
  }

  async delete(e) {
    e.preventDefault();

    try {
      const id = this.props.match.params.id;

      const url = `${baseUrl}/users/${id}`;
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });

      if (response.status === 204) {
        this.setState({
          hasCar: true,
        });

        return;
      }

      this.setState({
        isDeleted: true,
      });

      this.props.history.push("/benutzer/");
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async fetchUser() {
    try {
      const id = this.props.match.params.id;
      const url = `${baseUrl}/users/${id}`;
      const response = await fetch(url, {
        credentials: "include",
      });

      const { username, role } = await response.json();

      this.setState({
        username,
        role,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    try {
      const id = this.props.match.params.id;
      const { username, password, role } = this.state;
      const url = `${baseUrl}/users/${id}`;

      const user = {
        username,
        password,
        role,
      };

      await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      this.setState({
        isSaved: true,
      });

      this.props.history.push("/benutzer");
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.fetchUser();
  }

  render() {
    const siteContent = this.state.isError ? <FetchError /> : this.renderForm();

    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h1>Benutzer bearbeiten</h1>
        </div>

        {this.renderHasCarWarning()}
        {siteContent}
      </div>
    );
  }

  renderForm() {
    const { username, role } = this.state;

    const readyToDelete = this.state.readyToDelete;
    const deleteButton = readyToDelete ? (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-danger mr-2"
          onClick={this.delete}
        >
          Benutzerkonto löschen
        </button>
        <button
          type="button"
          className="btn btn-success mr-2"
          onClick={this.prepareDelete}
        >
          Abbrechen
        </button>
      </React.Fragment>
    ) : (
      <button
        type="button"
        className="btn btn-danger mr-2"
        onClick={this.prepareDelete}
      >
        Löschen
      </button>
    );

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Benutzername</label>
          <input
            type="text"
            className="form-control"
            id="username"
            placeholder="Benutzername"
            value={username}
            onChange={this.handleChange}
            required={true}
          />
        </div>

        <div className="form-group">
          <label htmlFor="role">Benutzerrolle</label>
          <select
            id="role"
            className="form-control"
            onChange={this.handleChange}
            value={role}
          >
            <option value="DRIVER">Fahrer</option>
            <option value="DISPATCHER">Disponent</option>
          </select>
        </div>

        <div className="form-group ml-1 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="changePassword"
            checked={this.state.changePassword}
            onChange={this.handleCheckboxChange}
          />
          <label className="form-check-label" for="changePassword">
            Passwort ändern
          </label>
        </div>
        {this.renderPasswordChange()}

        <div className="d-flex">
          {deleteButton}
          <div className="ml-auto">
            <button type="submit" className="btn btn-success mr-2">
              Änderungen speichern
            </button>
            <Link to="/benutzer" className="btn btn-secondary mr-2">
              Zurück
            </Link>
          </div>
        </div>
      </form>
    );
  }

  renderPasswordChange() {
    if (!this.state.changePassword) {
      return;
    }

    const { password, passwordValidation } = this.state;

    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor="password">
            Passwort{" "}
            <small className="text-muted">
              (Eingabe und Bestätigung nur bei Passwortänderung)
            </small>
          </label>
          <input
            type="text"
            className="form-control"
            id="password"
            placeholder="Passwort eingeben"
            value={password}
            onChange={this.handleChange}
          />
          <small>
            Muss mindestens eine Zahl und einen Groß- und Kleinbuchstaben sowie
            mindestens 8 oder mehr Zeichen enthalten.
          </small>
        </div>

        <div className="form-group">
          <label htmlFor="passwordValidation">Passwort bestätigen</label>
          <input
            type="text"
            className="form-control"
            id="passwordValidation"
            placeholder="Passwort bestätigen"
            value={passwordValidation}
            onChange={this.handleChange}
          />
          <small>Muss dem eingegebenen Passwort entsprechen.</small>
        </div>
      </React.Fragment>
    );
  }

  renderHasCarWarning() {
    if (!this.state.hasCar) {
      return;
    }

    return (
      <div className="alert alert-danger" role="alert">
        Das Benutzerkonto kann nicht gelöscht werden, solange es einem Fahrzeug
        zugeordnet ist.
      </div>
    );
  }
}

export default EditUser;
