import Dexie from "dexie";

const dbName = "DailyRidesListDb";

const db = new Dexie(dbName);

db.version(1).stores({
  client: "_id",
  cars: "_id",
  rides: "_id",
  synchService: "_id",
});

class ClientDbService {
  async get(id) {
    try {
      return await db.client.get(id);
    } catch (err) {
      return err;
    }
  }

  async getAll() {
    try {
      return await db.table("client").toArray();
    } catch (err) {
      return err;
    }
  }

  async put(client) {
    try {
      return await db.client.put({ id: client._id, ...client });
    } catch (err) {
      return err;
    }
  }

  async update(id, client) {
    try {
      return await db.client.update(id, client);
    } catch (err) {
      return err;
    }
  }

  async delete(id) {
    try {
      return await db.client.delete(id);
    } catch (err) {
      return err;
    }
  }

  async clear() {
    try {
      return await db.client.clear();
    } catch (err) {
      return err;
    }
  }
}

class CarsDbService {
  async get(id) {
    try {
      return await db.cars.get(id);
    } catch (err) {
      return err;
    }
  }

  async getAll() {
    try {
      return await db.table("cars").toArray();
    } catch (err) {
      return err;
    }
  }

  async put(car) {
    try {
      return await db.cars.put({ id: car._id, ...car });
    } catch (err) {
      return err;
    }
  }

  async putMany(cars) {
    try {
      return await db.cars.bulkPut(cars);
    } catch (err) {
      return err;
    }
  }

  async update(id, car) {
    try {
      return await db.cars.update(id, car);
    } catch (err) {
      return err;
    }
  }

  async delete(id) {
    try {
      return await db.cars.delete(id);
    } catch (err) {
      return err;
    }
  }

  async clear() {
    try {
      return await db.cars.clear();
    } catch (err) {
      return err;
    }
  }
}

class RidesDbService {
  // Get one ride by id
  async get(id) {
    try {
      return await db.rides.get(id);
    } catch (err) {
      return err;
    }
  }

  // Get all rides in table
  async getAll() {
    try {
      return await db.table("rides").toArray();
    } catch (err) {
      return err;
    }
  }

  // Add one (new) ride
  async put(ride) {
    try {
      return await db.rides.put({ id: ride._id, ...ride });
    } catch (err) {
      return err;
    }
  }

  // Add many rides
  async putMany(rides) {
    try {
      return await db.rides.bulkPut(rides);
    } catch (err) {
      return err;
    }
  }

  // Update one ride
  async update(id, ride) {
    try {
      return await db.rides.update(id, ride);
    } catch (err) {
      return err;
    }
  }

  // Delete one ride
  async delete(id) {
    try {
      return await db.rides.delete(id);
    } catch (err) {
      return err;
    }
  }

  clear() {
    try {
      db.rides.clear();
    } catch (err) {
      return err;
    }
  }
}

class SynchDbService {
  // Get one ride by id
  async get(id) {
    try {
      return await db.synchService.get(id);
    } catch (err) {
      return err;
    }
  }

  // Get all rides in table
  async getAll() {
    try {
      const docs = await db.table("synchService").toArray();
      return docs;
    } catch (err) {
      return err;
    }
  }

  // Add one (new) ride
  async put(ride) {
    try {
      return await db.synchService.put({ id: ride._id, ...ride });
    } catch (err) {
      return err;
    }
  }

  // Add many rides
  async putMany(rides) {
    try {
      return await db.synchService.bulkPut(rides);
    } catch (err) {
      return err;
    }
  }

  // Update one ride
  async update(id, ride) {
    try {
      return await db.synchService.update(id, ride);
    } catch (err) {
      return err;
    }
  }

  // Delete one ride
  async delete(id) {
    try {
      return await db.synchService.delete(id);
    } catch (err) {
      return err;
    }
  }

  async clear() {
    try {
      return await db.SynchService.clear();
    } catch (err) {
      return err;
    }
  }
}

export const CarsService = new CarsDbService();
export const ClientService = new ClientDbService();
export const RidesService = new RidesDbService();
export const SynchService = new SynchDbService();
