import React from "react";

import OptionalInputTag from "./OptionalInputTag";

const DistrictInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="city">Ortsteil</label>
      <OptionalInputTag />
      <input
        type="text"
        className="form-control"
        id="district"
        value={props.district}
        onChange={props.handleChange}
      />
    </div>
  );
};

export default DistrictInput;
