import React from "react";
import { Route, Switch } from "react-router-dom";

import CreateUser from "./Create";
import EditUser from "./Edit";
import UserList from "./List";

const UserIndex = (props) => {
  return (
    <Switch>
      <Route exact path="/benutzer/bearbeiten" component={CreateUser} />
      <Route exact path="/benutzer/bearbeiten/:id" component={EditUser} />
      <Route component={UserList} />
    </Switch>
  );
};

export default UserIndex;
