import React from "react";
import { Route, Switch } from "react-router";

import CarsIndex from "./components/car/Index";
import ClientIndex from "./components/client/Index";
import CustomerIndex from "./components/customer/Index";
import Dashboard from "./components/dashboard/Index"
import Footer from "./components/Footer";
import Login from "./components/Login";
import Navigation from "./components/Navigation";
import RidesIndex from "./components/ride/Index";
import RootAdministration from "./components/RootAdministration";
import UserIndex from "./components/user/Index";

import { baseUrl } from "./utils/baseUrl";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthError: false,
    };

    this.renderContent = this.renderContent.bind(this);
  }

  async checkSession() {
    if (!navigator.onLine) {
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/users/session`, {
        credentials: "include",
      });

      if (response.status === 403) {
        localStorage.clear("user");

        window.location.href = "/";
      }
    } catch {
      this.setState({
        isAuthError: true,
      });
    }
  }

  render() {
    console.log(process.env)
    return (
      <div className="App">
        <Navigation />

        <div id="content">
          {this.renderSessionWarning()}
          {this.renderContent()}
        </div>
        <Footer />
      </div>
    );
  }

  renderSessionWarning() {
    if (!this.state.isAuthError) {
      return;
    }

    return (
      <div className="alert alert-info text-center" role="alert">
        Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.
      </div>
    );
  }

  renderContent() {
    const session = localStorage.getItem("user");

    return session ? this.renderAuthenticated() : this.renderAnonymous();
  }

  renderAuthenticated() {
    this.checkSession();

    const user = localStorage.getItem("user");
    const userObject = JSON.parse(user);

    const isDriver = userObject.role === "DRIVER";

    if (isDriver) {
      return this.renderDriverContent();
    }

    return this.renderSupervisorContent();
  }

  renderSupervisorContent() {
    return (
      <Switch>
        <Route path="/fahrten" component={RidesIndex} />
        <Route path="/fahrzeuge" component={CarsIndex} />
        <Route path="/benutzer" component={UserIndex} />
        <Route path="/stammdaten" component={ClientIndex} />
        <Route path="/root" component={RootAdministration} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/" component={CustomerIndex} />
      </Switch>
    );
  }

  renderDriverContent() {
    return (
      <Switch>
        <Route path="/fahrten" component={RidesIndex} />
        <Route path="/" component={RidesIndex} />
      </Switch>
    );
  }

  renderAnonymous() {
    return (
      <Switch>
        <Route path="/login" component={Login} />
        <Route component={Login} />
      </Switch>
    );
  }
}

export default App;
