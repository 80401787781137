import React from "react";

const ConsumptionSiteInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="consumptionSite">Verbrauchsstelle</label>
      <input
        type="string"
        className="form-control"
        id="consumptionSite"
        value={props.consumptionSite}
        onChange={props.handleChange}
        required={false}
      />
    </div>
  );
};

export default ConsumptionSiteInput;
