import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSyncAlt,
  faCloudUploadAlt,
  faInbox,
} from "@fortawesome/free-solid-svg-icons";

import React from "react";

import Create from "../Create";
import FetchError from "../../shared/FetchError";
import List from "./List";
import StateFilter from "../../shared/StateFilter";
import { baseUrl } from "../../../utils/baseUrl";
import {
  CarsService,
  ClientService,
  RidesService,
  SynchService,
} from "../../../services/DBService";

function getFilterWarning(filter) {
  switch (filter) {
    case 0:
      return "Nichts zu tun. Für heute alles erledigt.";
    case 1:
      return "Heute noch keine erledigten Abfuhren vorhanden. Oder schon synchronisiert.";
    case 2:
      return "Heute noch keine abgehlenten Abfuhren vorhanden. Oder schon synchronisiert.";
    case 3:
      return "Für das gewählte Fahrzeug sind heute keine Abfuhren vorhanden.";
    default:
      return "";
  }
}

class ListIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCreateRide: false,
      filter: 0,

      fetchCarsError: false,
      fetchClientError: false,
      fetchRidesError: false,

      isDownloadError: false,
      isUploadError: false,

      isDeleteTableError: false,

      isUploaded: false,
      readyToSynch: [],
      ridesUploaded: 0,

      synchEmptyError: false,

      isSynchError: false,
      isSynched: false,
      lastSynch: "",

      userSelected: "",
    };

    this.clearTables = this.clearTables.bind(this);
    this.download = this.download.bind(this);
    this.upload = this.upload.bind(this);
    this.synchronize = this.synchronize.bind(this);

    this.fetchCars = this.fetchCars.bind(this);
    this.fetchClient = this.fetchClient.bind(this);
    this.fetchRides = this.fetchRides.bind(this);

    this.handleCarChange = this.handleCarChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);

    this.setCars = this.setCars.bind(this);
    this.setListState = this.setListState.bind(this);
    this.setRides = this.setRides.bind(this);

    this.toggleCreateRide = this.toggleCreateRide.bind(this);
  }

  async clearTables() {
    if (this.state.isUploadError || this.state.isSynchError) {
      return;
    }

    if (!this.state.isUploaded) {
      return;
    }

    try {
      RidesService.clear();
      await CarsService.clear();
      await ClientService.clear();
      await SynchService.clear();
    } catch (error) {
      this.setState({
        isDeleteTableError: true,
        deleteTableErrorMessage: error,
      });
    }
  }

  handleCarChange(e) {
    e.preventDefault();

    this.setState({
      userSelected: e.target.value,
    });
  }

  handleFilter(e) {
    e.preventDefault();

    const filter = parseInt(e.target.value);

    this.setState({
      filter,
    });
  }

  async fetchCars() {
    try {
      const url = `${baseUrl}/cars`;
      const respone = await fetch(url, {
        credentials: "include",
      });

      const cars = await respone.json();
      return cars;
    } catch {
      this.setState({
        fetchCarsError: true,
      });
    }
  }

  async fetchClient() {
    try {
      const url = `${baseUrl}/client`;
      const response = await fetch(url, {
        credentials: "include",
      });

      const result = await response.json();
      const client = result;

      return client;
    } catch {
      this.setState({
        fetchClientError: true,
      });
    }
  }

  async fetchRides() {
    try {
      const response = await fetch(`${baseUrl}/rides`, {
        credentials: "include",
      });

      const result = await response.json();
      const rides = result.rides.filter((ride) => {
        return ride.consumer !== null;
      });

      return rides;
    } catch {
      this.setState({
        fetchRidesError: true,
      });
    }
  }

  async download() {
    if (this.state.isUploadError) {
      return;
    }

    try {
      const cars = await this.fetchCars();
      const client = await this.fetchClient();
      const rides = await this.fetchRides();

      if (
        this.state.fetchCarsError ||
        this.state.fetchClientError ||
        this.state.fetchRidesError
      ) {
        this.setState({
          isSynchError: true,
          isDownloadError: true,
        });

        return;
      }

      CarsService.putMany(cars);
      ClientService.put(client);
      RidesService.putMany(rides);

      this.setListState();
    } catch {
      this.setState({
        isDownloadError: true,
      });
    }
  }

  async upload() {
    try {
      let rides = await SynchService.getAll();

      if (rides.length === 0) {
        this.setState({
          synchEmptyError: true,
        });

        return;
      }

      let arr = [];
      for (const ride of rides) {
        arr.push(ride.id);
      }

      const url = `${baseUrl}/rides`;

      const response = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rides),
      });

      const result = await response.json();

      if (result.success) {
        for (const el of arr) {
          await RidesService.delete(el);
          await SynchService.delete(el);
        }

        this.setState({
          isUploaded: true,
          ridesUploaded: arr.length,
          readyToSynch: [],
        });
      }

      if (this.state.isUploadError) {
        this.setState({
          isUploadError: false,
        });
      }
    } catch {
      this.setState({
        isUploadError: true,
      });
    }
  }

  async setListState() {
    await this.setRides();
    await this.setCars();

    const dateObject = new Date();
    const date = dateObject.toLocaleTimeString();

    this.setState({
      lastSynch: date,
    });
  }

  async setRides() {
    try {
      const rides = await RidesService.getAll();
      this.setState({ rides });
    } catch (error) {
      console.log("setRides Error: ", error);
    }
  }

  async setCars() {
    try {
      const cars = await CarsService.getAll();
      this.setState({ cars }, () => {
        this.setUser();
      });
    } catch (error) {
      console.log("setCars Error: ", error);
    }
  }

  setUser() {
    if (!this.state.cars || this.state.cars.length === 0) {
      return;
    }

    const session = localStorage.getItem("user");
    const user = JSON.parse(session);

    const currentCar = this.state.cars.find((x) => x.driver._id === user._id);
    const currentUser = currentCar ? currentCar.driver._id : undefined;

    const userIsDriver = currentUser ? true : false;

    if (userIsDriver) {
      this.setState({
        userSelected: currentUser,
      });

      return;
    }

    if (this.state.rides.length === 0) {
      this.setState({
        userSelected: [],
      });
    }

    this.setState({
      userSelected: this.state.cars[0].driver._id,
    });
  }

  async synchronize() {
    try {
      await this.upload();
      await this.clearTables();
      await this.download();

      this.setState({
        isSynched: true,
      });
    } catch {
      this.setState({
        isSynchError: true,
      });
    }
  }

  toggleCreateRide() {
    this.setState((prevState) => ({
      showCreateRide: !prevState.showCreateRide,
    }));
  }

  async getDocumentsToSynch() {
    const documents = await SynchService.getAll();

    this.setState({
      readyToSynch: documents,
    });
  }

  async componentDidMount() {
    this.setListState();

    const docs = await SynchService.getAll();
    this.setState({
      readyToSynch: docs,
    });
  }

  render() {
    const isDisabled = !navigator.onLine;

    return (
      <div className="container">
        <div className="d-flex pb-2 mt-4 mb-4 border-bottom">
          <h2>Fahrtenliste Tag</h2>
          <span className="ml-auto">{this.renderCarSelect()}</span>
        </div>
        {this.renderSynchErrorWarning()}
        <div id="daily-panel" className="mb-2">
          <button
            className="btn btn-primary mr-1"
            data-toggle="collapse"
            data-target="#create-ride"
            aria-expanded="false"
            onClick={this.toggleCreateRide}
            disabled={isDisabled}
          >
            Fahrt hinzufügen
          </button>
          <button
            className="btn btn-primary mr-1"
            onClick={this.synchronize}
            disabled={isDisabled}
          >
            Synchronisieren
          </button>
        </div>
        {this.renderFetchError()}
        {this.renderCreate()}
        {this.renderList()}
      </div>
    );
  }

  renderFetchError() {
    if (!this.state.isError) {
      return;
    }

    return <FetchError />;
  }

  renderCreate() {
    if (!this.state.showCreateRide) {
      return;
    }

    return (
      <div id="create-ride">
        <div className="card mt-3 mb-3">
          <div className="card-header">Neue Fahrt hinzufügen</div>
          <div className="card-body">
            <Create
              onCreated={this.refresh}
              toggleCreateRide={this.toggleCreateRide}
            />
          </div>
        </div>
      </div>
    );
  }

  renderList() {
    const today = new Date();

    const lastSynch = this.state.isSynched ? this.state.lastSynch : "./.";

    const lastSynchField = (
      <p className="small mt-1 mb-1 mr-2 text-muted">
        <span className="mr-1">
          <FontAwesomeIcon fixedWidth={true} icon={faSyncAlt} />
        </span>
        <span className="badge badge-secondary ml-1">{lastSynch}</span>
      </p>
    );

    const ridesSynchedField = (
      <p className="small mt-1 mb-1 mr-2 text-muted">
        <span className="mr-1">
          <FontAwesomeIcon fixedWidth={true} icon={faCloudUploadAlt} />
          <span className="badge badge-secondary ml-1">
            {this.state.ridesUploaded}
          </span>
        </span>
      </p>
    );

    const readyToSynch = this.state.readyToSynch.length;

    const connectionIndicator = navigator.onLine ? (
      <span className="badge badge-success">Online</span>
    ) : (
      <span className="badge badge-danger">Offline</span>
    );

    return (
      <React.Fragment>
        <div className="pb-2 mt-4 border-bottom d-flex">
          <h3>Abfuhren {today.toLocaleDateString()}</h3>
          <span className="mt-auto ml-auto mr-2 text-muted">Status:</span>

          {this.renderStateFilter()}
        </div>
        <div className="d-flex">
          <p className="small mt-1 mb-1 mr-1 text-muted">
            {connectionIndicator}
          </p>

          {lastSynchField}
          {ridesSynchedField}

          <p className="small mt-1 mb-1 mr-1 text-muted">
            <span className="mr-1">
              <FontAwesomeIcon fixedWidth={true} icon={faInbox} />
            </span>
            <span className="badge badge-secondary">{readyToSynch}</span>
          </p>
        </div>
        <div id="accordion" className="mb-5">
          {this.renderListWarning()}
          {this.renderListItems()}
          <hr />
        </div>
      </React.Fragment>
    );
  }

  renderListItems() {
    if (!this.state.rides || this.state.rides.length === 0) {
      return;
    }

    // Sort rides by date
    let today = new Date();
    today = new Date(today.setHours(23 + 2, 59)); // +2 hours for UTC time

    const timeCleanedRides = this.state.rides.filter(
      (x) => new Date(x.date) <= today
    );

    // Sort rides by city
    let rides = timeCleanedRides.sort((a, b) => {
      if (a.consumer.adress.city === b.consumer.adress.city) {
        return a.consumer.adress.street.localeCompare(b.consumer.adress.street);
      }

      return a.consumer.adress.city.localeCompare(b.consumer.adress.city);
    });

    // Query rides by state and user
    const userSelected = this.state.userSelected;
    let filterQuery = (x) => {
      if (!x.car) {
        return;
      }

      return x.state === this.state.filter && x.car.driver === userSelected;
    };

    // Query all rides
    if (this.state.filter === 3) {
      filterQuery = (x) => x.car.driver === userSelected;
    }

    const session = localStorage.getItem("user");
    const user = JSON.parse(session);

    if (!user) {
      return
    }

    user.selected = this.state.userSelected;

    const filteredRides = rides.filter(filterQuery);

    if (!filteredRides || filteredRides.length === 0) {
      const warningText = getFilterWarning(this.state.filter);

      return (
        <div className="alert alert-success text-center" role="alert">
          {warningText}
        </div>
      );
    }

    return <List rides={filteredRides} user={user} />;
  }

  renderCarSelect() {
    if (!this.state.cars) {
      return;
    }

    const carOptions = this.state.cars.map((x, i) => (
      <option key={i} value={x.driver._id}>
        {x.license}
      </option>
    ));

    return (
      <div className="d-flex">
        <span className="mt-auto mr-2 text-muted">Fahrzeug:</span>
        <select
          id="car"
          className="form-control"
          onChange={this.handleCarChange}
          value={this.state.userSelected}
        >
          {carOptions}
        </select>
      </div>
    );
  }

  renderStateFilter() {
    const session = localStorage.getItem("user");
    const user = JSON.parse(session);

    if (!user || !this.state.userSelected) {
      return;
    }

    let isDisabled = this.state.userSelected !== user._id;

    if (user.role !== "DRIVER") {
      isDisabled = false;
    }

    return (
      <StateFilter
        filter={this.state.filter}
        handleFilter={this.handleFilter}
        disabled={isDisabled}
      />
    );
  }

  renderSynchErrorWarning() {
    if (
      this.state.isUploadError ||
      this.state.isDownloadError ||
      this.state.isSynchError
    ) {
      return (
        <div className="alert alert-danger mt-3" role="alert">
          Bei der Synchronisation is ein Fehler aufgetreten. Bitte versuchen Sie
          es später erneut. Ihre aktuellen Abfuhren wurden gespeichert und
          können weiter bearbeitet werden.
        </div>
      );
    }
  }

  renderListWarning() {
    if (!this.state.rides || this.state.rides.length === 0) {
      return (
        <div className="alert alert-info mt-3" role="alert">
          Willkommen! Keine Daten vorhanden. Bitte synchronisieren.
        </div>
      );
    }

    return;
  }
}

export default ListIndex;
