import React from "react";

const CityInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="city">Ort</label>
      <input
        type="text"
        className="form-control"
        id="city"
        value={props.city}
        onChange={props.handleChange}
        required={true}
      />
    </div>
  );
};

export default CityInput;
