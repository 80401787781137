import React from "react";

import {
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  PolarAngleAxis,
} from "recharts";

import { ocean } from "./ColorSchemes";

const circleSize = 275;

const RadialBarChartVisualization = ({ percentageDone }) => {
  const data = [{ name: "Percentage Done", value: percentageDone }];

  return (
    <ResponsiveContainer width="100%" height={275}>
      <RadialBarChart
        cx="50%"
        cy="50%"
        innerRadius={100}
        outerRadius={250}
        barSize={20}
        data={data}
        startAngle={90}
        endAngle={-270}
      >
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          angleAxisId={0}
          tick={false}
        />
        <RadialBar  
          background
          clockWise
          dataKey="value"
          cornerRadius={circleSize / 2}
          fill={ocean.light}
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          className="progress-label"
        >
          {data[0].value}%
        </text>
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

export default RadialBarChartVisualization;
