import React, { useEffect } from "react";
import Modal from "react-modal";

import { states } from "../../../utils/states";
import Table from "./Table";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "100vh",
    overflowY: "auto",
    maxWidth: "100vw",
    overflowX: "auto",
  },
};

const DashboardModal = ({ modalRideState, modalIsOpen, closeModal }) => {
  useEffect(() => {
    Modal.setAppElement("body");
  });

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      preventScroll={false}
      contentLabel={`${states[modalRideState]}`}
    >
      <h4>{states[modalRideState]}</h4>
      <Table modalRideState={modalRideState} />
    </Modal>
  );
};

export default DashboardModal;
